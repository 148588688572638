import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { AppBar, Box, Typography } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'src/store';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import useMounted from 'src/hooks/useMounted';
import { setActiveOrgId, setActiveTeamId, setBannerUrl, setOrgStructures, updateCurExRate } from 'src/slices/organization';
import { initChatApi, initNeedReloads, loadContact, loadWallet } from 'src/worker/init';
import { getNavbarApprisingCount } from 'src/utils/getApprisingCount';
import { lambdaGetFeedback, lambdaGetOrgActivatedMembersOfUser, lambdaGetOrgBannerUrl, lambdaJoinAsClient } from 'src/aws/lambdaDispatch';
import toast from 'react-hot-toast';
import OrgPopover from './OrgPopover';
import { loadApprising } from 'src/slices/apprising';
import { getPossibleHrefs } from 'src/utils/filterUrlsByRole';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { ACCZIOM_CLIENT, ACCZIOM_MEMBER, ACCZIOM_ORG, ACCZIOM_TEAM, ACCZIOM_USER, allCurrencyUnits, CONNECT_DLG_JOIN, INNERORG_CHAT_THREAD, NAVBAR_HEIGHT, REQUIRE_AUTH_MODE, REQUIRE_NONE_MODE, REQUIRE_NOT_AUTH_MODE, STATUS_ACTIVATED, STATUS_PENDING } from 'src/globals';
import { fetchUrl } from 'src/utils/fileUpDownload';
import { wsConnect, wsDisconnect, wsTeamConnect, wsTeamDisconnect } from 'src/apis/wsApi';
import useSearch from 'src/hooks/useSearch';
import getUserDisplayName from 'src/utils/getUserDisplayName';
import { setSearchHistory } from 'src/slices/favorite';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NavMenuItem, { Item } from 'src/components/interact/NavMenuItem';
import { setConnectDlgOpen } from 'src/slices/user';
import BalanceIcon from '@mui/icons-material/Balance';
import { Adjustments } from 'src/icons/Organization';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import AirlineStopsOutlinedIcon from '@mui/icons-material/AirlineStopsOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import SearchInput from 'src/components/interact/SearchInput';
import { setSelectedMode } from 'src/slices/chat';
import { chatApi } from 'src/apis/chatApi';
import useAuth from 'src/hooks/useAuth';
import NavItem from '../interact/NavItem';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import { UserAvatar } from '../SmallUserInfo';
import { getActiveOrgInfo, getActiveOrgMinInfo, getActiveType } from 'src/utils/getActiveOrgInfo';
import CircleType from 'circletype';
import LogoutIcon from '@mui/icons-material/Logout';
import { getExchangeRatesForOneUSD } from 'src/utils/priceUtils';
import { MdOutlineForum } from 'react-icons/md';
import { clearShareState } from 'src/slices/share';
import { loadFeedback } from 'src/slices/feedback';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
// import useSettings from 'src/hooks/useSettings';

const DashboardItems: Item[] = [
  {
    title: 'Marketplace',
    href: '/marketplace',
    onClick: null,
    authRequired: REQUIRE_NONE_MODE,
    icon: BalanceIcon,
    items: [
      {
        title: 'Organizations',
        href: '/marketplace/organization',
        onClick: null,
        icon: Adjustments
      },
      {
        title: 'Services',
        href: '/marketplace/microservice',
        onClick: null,
        icon: HomeRepairServiceOutlinedIcon
      },
      {
        title: 'Assets',
        href: '/marketplace/asset',
        onClick: null,
        icon: BusinessOutlinedIcon
      }
    ]
  },
  {
    title: 'Management',
    href: '/management',
    onClick: null,
    authRequired: REQUIRE_AUTH_MODE,
    icon: SettingsSuggestOutlinedIcon,
    items: [
      {
        title: 'Services',
        href: '/management/microservice',
        onClick: null,
        icon: HomeRepairServiceOutlinedIcon
      },
      {
        title: 'Assets',
        href: '/management/asset',
        onClick: null,
        icon: BusinessOutlinedIcon
      },
      {
        title: 'Structure',
        href: '/management/structure',
        onClick: null,
        icon: AccountTreeOutlinedIcon
      },
      {
        title: 'Network',
        href: '/management/network',
        onClick: null,
        icon: GroupOutlinedIcon
      },
      {
        title: 'Files',
        href: '/management/file',
        onClick: null,
        icon: SourceOutlinedIcon
      },
      {
        title: 'Assign',
        href: '/management/assign',
        onClick: null,
        icon: AssignmentTurnedInOutlinedIcon
      },
      {
        title: 'Templates',
        href: '/management/templates',
        onClick: null,
        icon: TextSnippetOutlinedIcon
      }
    ]
  },
  {
    title: 'Deals',
    href: '/deal',
    onClick: null,
    authRequired: REQUIRE_AUTH_MODE,
    icon: MonetizationOnOutlinedIcon,
    items: [
      {
        title: 'Sales',
        href: '/deal/sale',
        onClick: null,
        icon: SellOutlinedIcon
      },
      {
        title: 'Purchases',
        href: '/deal/purchase',
        onClick: null,
        icon: ShoppingBasketOutlinedIcon
      },
      {
        title: 'Referrals',
        href: '/deal/referral',
        onClick: null,
        icon: AirlineStopsOutlinedIcon
      },
      {
        title: 'Auto Debits',
        href: '/deal/autodebit',
        onClick: null,
        icon: AutoAwesomeOutlinedIcon
      }
    ]
  },
  {
    title: 'Communication',
    href: '/communication',
    onClick: null,
    authRequired: REQUIRE_AUTH_MODE,
    icon: ChatOutlinedIcon,
    items: [
      {
        title: 'External Messages',
        href: '/communication/external',
        onClick: null,
        icon: CampaignOutlinedIcon
      },
      {
        title: 'Internal Messages',
        href: '/communication/internal',
        onClick: null,
        icon: TextsmsOutlinedIcon
      },
      {
        title: 'Community',
        href: 'https://community.accziom.com',
        onClick: null,
        icon: MdOutlineForum
      }
    ]
  },
  {
    title: 'Configuration',
    href: '/configuration',
    onClick: null,
    authRequired: REQUIRE_AUTH_MODE,
    icon: SettingsOutlinedIcon,
    items: []
  },
  {
    title: 'Sign In',
    href: '/login',
    onClick: null,
    authRequired: REQUIRE_NOT_AUTH_MODE,
    icon: AccountCircleOutlinedIcon,
    items: []
  },
  {
    title: 'Sign Up',
    href: '/register',
    onClick: null,
    authRequired: REQUIRE_NOT_AUTH_MODE,
    icon: PersonAddAltOutlinedIcon,
    items: []
  }
];

const JoinUsItem: Item = {
  title: 'Join Us',
  href: null,
  onClick: null,
  authRequired: REQUIRE_NONE_MODE,
  icon: SensorOccupiedIcon,
  items: []
};

const SignOutItem: Item = {
  title: 'Sign Out',
  href: null,
  onClick: null,
  authRequired: REQUIRE_AUTH_MODE,
  icon: LogoutIcon,
  items: []
};

const HelpItem: Item = {
  title: 'Support',
  href: null,
  onClick: null,
  authRequired: REQUIRE_NONE_MODE,
  icon: QuestionMarkIcon,
  items: []
};

const searchBarUrls = {
  notAuthUrlsForSame: [
    '/',
    '/marketplace/organization',
    '/marketplace/microservice',
    '/marketplace/asset'
  ],
  notAuthUrlsForStart: [],
  authUrlsForSame: [
    '/marketplace/organization',
    '/marketplace/microservice',
    '/marketplace/asset',
    '/management/microservice',
    '/management/asset',
    '/management/structure/client',
    '/management/structure/supplier',
    '/management/structure/member',
    '/management/structure/role',
    '/management/structure/team',
    '/management/network/findpartner',
    '/management/network/partner',
    '/management/network/referral',
    '/management/network/contact',
    '/management/file',
    '/management/assign/deal',
    '/management/assign/service',
    '/management/assign/asset',
    '/management/assign/file',
    '/management/assign/referral',
    '/management/assign/partner',
    '/management/templates/shared',
    '/management/templates/draft',
    '/deal/sale',
    '/deal/purchase',
    '/deal/referral',
    '/deal/autodebit',
    '/bs/search'
  ],
  authUrlsForStart: [
    '/communication/external',
    '/communication/internal'
  ],
};

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: '3px 0px 5px rgba(0, 0, 0, .5)',
    // borderColor: theme.palette.background.paper,
    // borderBottomWidth: '1px',
    // borderBottomStyle: 'solid'
  })
);

const LARGE_LOGO_SIZE = 64;
const TITLE_TEXT_RADIUS = 20;
const LOGO_BACK_SIZE = NAVBAR_HEIGHT;
const SHADOW_SIZE = 10;

const DashboardNavbar: FC = () => {
  const mounted = useMounted();
  const dispatch = useDispatch();
  const searchApi = useSearch();
  const navigate = useNavigate();
  // const { saveSettings } = useSettings();
  const { pathname } = useLocation();
  const { isAuthenticated, logout } = useAuth();
  const { domain: orgDomainName } = useParams();
  const { userInfo, inviteInfo, searchInfo, isSearching } = useSelector((state) => state.user);
  const { apprisings } = useSelector((state) => state.apprising);
  const { memberInfos, orgInfos, activeOrgId, orgsNeedReload } = useSelector((state) => state.organization);
  const { convs } = useSelector((state) => state.chat);
  const [isSwitchingOrg, setIsSwitchingOrg] = useState<boolean>(false);
  const [items, setItems] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const activeOrgMinInfo = getActiveOrgMinInfo();
  const activeOrgInfo = getActiveOrgInfo();
  const displayTitle = getUserDisplayName(activeOrgMinInfo).toUpperCase();
  const enabledSearch = (!isAuthenticated && !window.location.hostname.includes('.go.accziom.com') && (searchBarUrls.notAuthUrlsForSame.includes(pathname) || searchBarUrls.notAuthUrlsForStart.some((subHref) => pathname.startsWith(subHref))))
    || (isAuthenticated && !pathname.startsWith('/about/') && (searchBarUrls.authUrlsForSame.includes(pathname) || searchBarUrls.authUrlsForStart.some((subHref) => pathname.startsWith(subHref))));
  let circleType = null;

  const handleNavigateChat = (mode: string) => {
    chatApi.processBeforeChangeMode();
    dispatch(setSelectedMode(mode));
  };

  const handleGotoCommunity = () => {
    if (process.env.NODE_ENV === 'production') {
      const orgInfo = orgInfos.find((org) => org.organizationId === activeOrgId);
      window.open(`https://${orgInfo.domainName}.community.accziom.com`, '_blank');
    } else {
      window.open('http://localhost:3000', '_blank');
    }
  };

  const handleSearch = async (q: string): Promise<void> => {
    searchApi.current?.onSearch?.(q);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      if (pathname === '/' || pathname.startsWith('/marketplace')) {
        if (process.env.NODE_ENV === 'production') window.location.href = 'https://go.accziom.com';
        else navigate('/');
      }
    } catch (err) {
      console.log(err);
      toast.error('Unexpected error occured, Please try again!');
    }
  };

  const handleJoinWithDomain = async (domain: string): Promise<void> => {
    try {
      const res = await lambdaJoinAsClient(activeOrgMinInfo, domain);
      if (res.status === 'error') {
        throw new Error('org not found');
      } else if (res.status === 'exist') {
        toast.error('You are already a client in accziom!');
      } else if (res.status === 'exist member') {
        toast.error('You have already tried to register as member!');
      } else if (res.status === 'exist joint') {
        toast.error('You already sent join request!');
      } else if (res.status === 'accepted') {
        toast.success('Successfully joint.');
      } else {
        toast.success('Successfully sent join request.');
      }
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error('Failed to join!');
    }
  };

  const handleJoinWithOutDomain = (): void => {
    dispatch(setConnectDlgOpen(CONNECT_DLG_JOIN));
  };

  const setDashboardItems = () => {
    if (!mounted.current) return;
    const hrefs = activeOrgInfo?.status === STATUS_PENDING ? ['/deal/purchase'] : getPossibleHrefs();
    const mm : Item[] = [];
    if (!inviteInfo && !userInfo?.isFirst) {
      let joinUsMode = false;
      if (!isAuthenticated) {
        if (window.location.hostname.includes('.go.accziom.com')) {
          joinUsMode = true;
          mm.push({
            ...JoinUsItem,
            onClick: async () => { await handleJoinWithOutDomain(); }
          } as Item);
        }
      } else if (pathname.startsWith('/about/')) {
        joinUsMode = true;
        mm.push({
          ...JoinUsItem,
          onClick: async () => { await handleJoinWithDomain(orgDomainName); }
        } as Item);
      }
      if (!joinUsMode) {
        DashboardItems.forEach((item) => {
          if (!isAuthenticated && [REQUIRE_NONE_MODE, REQUIRE_NOT_AUTH_MODE].includes(item.authRequired)) mm.push(item);
          if (isAuthenticated && [REQUIRE_NONE_MODE, REQUIRE_AUTH_MODE].includes(item.authRequired)) {
            if (item.items.length < 1) {
              if (item.href && (hrefs.filter((h) => h.startsWith(item.href)).length > 0 || (item.href === '/configuration' && activeOrgInfo.status === STATUS_ACTIVATED))) mm.push(item);
            } else {
              const subItems = [];
              item.items.forEach((subItem) => {
                if (subItem.href && hrefs.filter((h) => h.startsWith(subItem.href)).length > 0) {
                  if (['/communication/external', '/communication/internal'].includes(subItem.href)) {
                    subItems.push({
                      ...subItem,
                      onClick: () => { handleNavigateChat(subItem.href.split('/').at(2)); }
                    });
                  } else if (subItem.href === 'https://community.accziom.com') {
                    subItems.push({
                      ...subItem,
                      href: null,
                      onClick: handleGotoCommunity
                    });
                  } else if (getActiveType(ACCZIOM_CLIENT, ACCZIOM_ORG) === ACCZIOM_CLIENT && subItem.href === '/management/network') {
                    subItems.push({
                      ...subItem,
                      href: '/management/network/contact'
                    });
                  } else subItems.push(subItem);
                }
              });
              if (subItems.length > 0) mm.push({ ...item, items: subItems });
            }
          }
        });
      }
    }
    setItems(mm);
  };

  useEffect(() => {
    setDashboardItems();
  }, [mounted, activeOrgId, pathname, isAuthenticated]);

  const getDataOnLoad = () => {
    if (!userInfo || loading || !orgsNeedReload) return;
    setLoading(true);
    lambdaGetOrgActivatedMembersOfUser(userInfo.uid)
      .then((res) => {
        const { memberInfos: members, orgInfos: organizations, categoryInfos: categories, roleInfos: strRoles, teamInfos: teams } = res;
        const roles = [];
        strRoles.forEach((item) => {
          roles.push({
            rid: item.rid,
            oid: item.oid,
            name: item.name,
            level: item.level,
            department: JSON.parse(item.department),
            assignment: JSON.parse(item.assignment),
            production: JSON.parse(item.production),
            transaction: JSON.parse(item.transaction),
            createdAt: item.createdAt,
            updatedAt: item.updatedAt
          });
        });
        const memberData = [];
        const orgData = [];
        const categoryData = [];
        const roleData = [];
        const teamData = [];
        const teamIds = [];
        members.forEach((member) => {
          if (member.rid) {
            const role = roles.find((item) => item.rid === member.rid);
            const departmentValues = Object.values(role.department).map((obj) => Object.values(obj).includes(true));
            const assignmentValues = Object.values(role.assignment).map((obj) => Object.values(obj).includes(true));
            const teamCount = member.tids.length;
            if (departmentValues.includes(true) || assignmentValues.includes(true) || teamCount > 0) {
              memberData.push(member);
              const organization = organizations.find((item) => item.organizationId === member.oid);
              if (organization) orgData.push(organization);
              const category = categories.find((cat) => `${cat.code}` === `${organization.anzicCategory}`);
              if (category) categoryData.push(category);
              if (role) roleData.push(role);
              member.tids.forEach((tid) => {
                if (!teamIds.includes(tid)) {
                  teamIds.push(tid);
                  const team = teams.find((item) => item.tid === tid);
                  teamData.push(team);
                }
              });
            }
          } else {
            memberData.push(member);
            const organization = organizations.find((item) => item.organizationId === member.oid);
            if (organization) orgData.push(organization);
            const category = categories.find((cat) => `${cat.code}` === `${organization.anzicCategory}`);
            if (category) categoryData.push(category);
          }
        });
        memberData.sort((member0, member1) => { if (member0.type < member1.type) return 1; if (member0.type > member1.type) return -1; return 0; }); // sort by type, client first, member second
        dispatch(setOrgStructures(memberData, orgData, categoryData, roleData, teamData));
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        toast.error('Failed to get organization information!');
      })
      .finally(() => {
        if (mounted.current) setLoading(false);
      });
  };

  const switchOrg = async (orgId: string, teamId: string): Promise<void> => {
    // saveSettings({ orgId, teamId });
    if (process.env.NODE_ENV === 'production') {
      if (orgId === activeOrgId) {
        dispatch(setActiveTeamId(teamId));
        loadApprising(null, teamId);
        wsTeamConnect({
          id: teamId,
          type: ACCZIOM_TEAM,
          name: teamId
        });
      } else {
        const orgInfo = orgInfos.find((item) => item.organizationId === orgId);
        if (orgInfo?.domainName) {
          const domain = `${orgInfo.domainName}.go.accziom.com`;
          if (domain !== window.location.host) window.location.href = `https://${domain}`;
        }
      }
      return;
    }
    if (orgId === activeOrgId) {
      dispatch(setActiveTeamId(teamId));
      loadApprising(null, teamId);
      wsTeamConnect({
        id: teamId,
        type: ACCZIOM_TEAM,
        name: teamId
      });
      return;
    }
    setIsSwitchingOrg(true);
    try {
      const curMember = memberInfos.find((member) => member.oid === orgId);
      const chatLoaded = await initChatApi(curMember.mid, dispatch);
      if (chatLoaded) {
        await loadWallet(curMember.type === ACCZIOM_MEMBER ? { id: orgId, type: ACCZIOM_ORG } : { id: userInfo.uid, type: ACCZIOM_USER }, dispatch);
        loadContact(curMember.type === ACCZIOM_MEMBER ? orgId : userInfo.uid, dispatch);
        dispatch(setActiveTeamId(teamId));
        loadApprising(curMember.type === ACCZIOM_MEMBER ? orgId : curMember.mid, teamId);
        lambdaGetFeedback(curMember.type === ACCZIOM_MEMBER ? orgId : curMember.mid).then((res) => {
          dispatch(loadFeedback(res.feedback, res.feedbackConnection));
        });
        const orgInfo = orgInfos.find((item) => item.organizationId === orgId);
        if (orgInfo.banner) {
          const newBannerUrl = await lambdaGetOrgBannerUrl(orgInfo.banner);
          const newLocalUrl = await fetchUrl(newBannerUrl);
          dispatch(setBannerUrl(newLocalUrl));
        } else dispatch(setBannerUrl(null));
        getExchangeRatesForOneUSD(allCurrencyUnits)
          .then((rates) => { dispatch(updateCurExRate(rates)); })
          .catch((err) => {
            toast.error('Failed to get exchange rate!');
            console.log(JSON.stringify(err));
          });
        dispatch(setSearchHistory([]));
        dispatch(clearShareState());
        dispatch(setActiveOrgId(orgId));
        initNeedReloads(dispatch);
        wsDisconnect();
        wsTeamDisconnect();
        wsConnect(curMember.type === ACCZIOM_MEMBER ? ({
          id: curMember.oid,
          type: ACCZIOM_ORG,
          name: orgInfos.find((v) => v.organizationId === orgId).tradingName
        }) : ({
          id: curMember.mid,
          type: ACCZIOM_CLIENT,
          name: getUserDisplayName(userInfo)
        }));
      } else toast.error('Chat has not been initialized.');
    } catch (err) {
      toast.error('Switching organization has been failed.');
    } finally {
      if (mounted.current) setIsSwitchingOrg(false);
    }
  };

  useEffect(() => {
    getDataOnLoad();
  }, [mounted, orgsNeedReload]);

  useEffect(() => {
    if (mounted.current) {
      getExchangeRatesForOneUSD(allCurrencyUnits)
        .then((rates) => { dispatch(updateCurExRate(rates)); })
        .catch((err) => {
          toast.error('Failed to get exchange rate!');
          console.log(err);
        });
    }
  }, [mounted]);

  useEffect(() => {
    const circularEle = document.getElementById('circular_title');
    if (mounted.current && isAuthenticated && circularEle) {
      document.getElementById('circular_title').innerHTML = displayTitle.length < 14 ? displayTitle : `${displayTitle.substring(0, 12)}...`;
      circleType = new CircleType(document.getElementById('circular_title'))
        .dir(1)
        .radius(LARGE_LOGO_SIZE / 2 + TITLE_TEXT_RADIUS);
    }
    return () => {
      if (mounted.current && isAuthenticated) {
        if (circleType) {
          circleType.destroy();
          circleType = null;
        }
      }
    };
  }, [mounted, isAuthenticated, displayTitle]);

  return (
    <DashboardNavbarRoot>
      <Box
        sx={{
          height: NAVBAR_HEIGHT,
          display: 'flex',
          alignItems: 'center',
          px: {
            sm: '70px',
            md: '70px',
            lg: 'calc(1.5% + 70px)',
            xl: 'calc(4% + 70px)'
          }
        }}
      >
        {isAuthenticated && !['/fillup', '/acceptinvite'].includes(pathname) ? (
          <Box
            sx={{
              width: '164px',
              height: '100%'
            }}
          >
            <Box
              sx={{
                width: '164px',
                height: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  position: 'relative',
                  alignItems: 'center'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: `${NAVBAR_HEIGHT - (LOGO_BACK_SIZE / 4) * 3}px`,
                    bgcolor: 'background.paper',
                    borderRadius: '50%',
                    width: LOGO_BACK_SIZE,
                    height: LOGO_BACK_SIZE,
                    boxShadow: '3px 0px 5px rgba(0, 0, 0, .5)'
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: `${NAVBAR_HEIGHT - (LOGO_BACK_SIZE / 4) * 3 - SHADOW_SIZE}px`,
                    bgcolor: 'background.paper',
                    width: LOGO_BACK_SIZE + SHADOW_SIZE * 2,
                    height: (LOGO_BACK_SIZE / 4) * 3 + SHADOW_SIZE
                  }}
                />
                <Typography
                  id="circular_title"
                  variant="body2"
                  fontWeight="bold"
                  color="textPrimary"
                  sx={{
                    position: 'absolute',
                    top: '4px',
                    width: '100%'
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: `${NAVBAR_HEIGHT - (LOGO_BACK_SIZE / 4) * 3 + (LOGO_BACK_SIZE - LARGE_LOGO_SIZE) / 2}px`,
                    bgcolor: 'background.paper',
                    borderRadius: '50%',
                    width: LARGE_LOGO_SIZE,
                    height: LARGE_LOGO_SIZE,
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ':hover': {
                      transform: 'scale(.95, .95)'
                    }
                  }}
                  onClick={() => {
                    navigate(activeOrgInfo.status === STATUS_PENDING ? '/payorgfee' : '/home');
                  }}
                >
                  <UserAvatar
                    userInfo={activeOrgMinInfo}
                    size={LARGE_LOGO_SIZE}
                    showType={false}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <NavItem
            sourcePath="/static/logo.svg"
            altName="Logo"
            height={16}
            href="/"
          />
        )}
        {enabledSearch && (
          <SearchInput
            placeholder={searchInfo?.placeHolder ? searchInfo.placeHolder : ''}
            hasButton={!!searchInfo?.hasButton}
            onSearch={handleSearch}
            isSearching={isSearching}
            disabled={false}
          />
        )}
        <Box flexGrow={1} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {
            items.map((item) => {
              let apprCount = 0;
              if (item.href === '/communication') apprCount = convs.filter((_conv) => _conv.unreadCount > 0).length;
              else if (item.href) apprCount = getNavbarApprisingCount(apprisings, item.href);
              return (
                <NavMenuItem
                  key={item.title}
                  isHorizontal={false}
                  data={{
                    ...item,
                    items: item.items.map((subItem) => {
                      if (!subItem.href) return subItem;
                      return {
                        ...subItem,
                        value: item.href.startsWith('/communication')
                          ? convs.filter((_conv) => _conv.unreadCount > 0 && ((subItem.href === '/communication/external' && _conv.type !== INNERORG_CHAT_THREAD) || (subItem.href === '/communication/internal' && _conv.type === INNERORG_CHAT_THREAD))).length
                          : getNavbarApprisingCount(apprisings, subItem.href)
                      };
                    })
                  }}
                  value={apprCount}
                />
              );
            })
          }
        </Box>
        {isAuthenticated && !['/fillup', '/acceptinvite'].includes(pathname) && (
          <OrgPopover
            loading={loading}
            onChange={switchOrg}
            isSwitchingOrg={isSwitchingOrg}
            onLogout={handleLogout}
          />
        )}
        {isAuthenticated && ['/fillup', '/acceptinvite'].includes(pathname) && (
          <NavMenuItem
            isHorizontal={false}
            data={{
              ...SignOutItem,
              onClick: handleLogout
            }}
            value={0}
          />
        )}
        <NavMenuItem
          isHorizontal={false}
          data={{
            ...HelpItem,
            onClick: () => { window.open('https://help.accziom.com/support/home', '_blank'); }
          }}
          value={0}
        />
      </Box>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
