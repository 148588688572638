import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import DashboardLayout from 'src/components/dashboard/DashboardLayout';
import LoadingScreen from 'src/components/layout/LoadingScreen';
import AuthGuard from 'src/components/layout/AuthGuard';
import GuestGuard from 'src/components/layout/GuestGuard';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages

const Diagram = Loadable(lazy(() => import('src/pages/Diagram')));
const OrgCreatePad = Loadable(lazy(() => import('src/components/Configuration/Organization/OrgCreatePad')));
const Dashboard = Loadable(lazy(() => import('src/pages/Dashboard')));
const OrgSearch = Loadable(lazy(() => import('src/pages/ClientDashboard/OrgSearch')));
const MicroserviceSearch = Loadable(lazy(() => import('src/pages/ClientDashboard/MicroserviceSearch')));
const AssetSearch = Loadable(lazy(() => import('src/pages/ClientDashboard/AssetSearch')));
const Fillup = Loadable(lazy(() => import('./pages/Fillup')));
const AcceptInvite = Loadable(lazy(() => import('./pages/AcceptInvite')));
const Chat = Loadable(lazy(() => import('src/pages/chat')));
const Ticket = Loadable(lazy(() => import('src/pages/ticket')));
const ErrorPage = Loadable(lazy(() => import('src/pages/NotFound')));
const NetworkView = Loadable(lazy(() => import('src/pages/mynetwork')));
const ServiceRequest = Loadable(lazy(() => import('src/pages/ServiceRequest/ServiceRequest')));
const Wallet = Loadable(lazy(() => import('src/pages/wallet')));
const MyFiles = Loadable(lazy(() => import('src/pages/MyFiles')));
const BusinessSearch = Loadable(lazy(() => import('src/components/tools/BusinessProfile')));
const MicroservicePage = Loadable(lazy(() => import('src/pages/microservices/MicroservicePage')));
const Configuration = Loadable(lazy(() => import('src/pages/Configuration')));
const StructureBoard = Loadable(lazy(() => import('src/pages/structure/StructureBoard')));
const Templates = Loadable(lazy(() => import('src/pages/templates')));
const TemplateEdit = Loadable(lazy(() => import('src/pages/templates/edit')));
const AssetPage = Loadable(lazy(() => import('src/pages/asset/AssetPage')));
const AssetRequest = Loadable(lazy(() => import('src/pages/AssetRequest')));
const AboutUs = Loadable(lazy(() => import('src/pages/aboutus/AboutUsWrap')));
const DirectOffer = Loadable(lazy(() => import('src/pages/directoffer')));
const AssignPad = Loadable(lazy(() => import('src/pages/assign')));
const PayOrgFee = Loadable(lazy(() => import('src/pages/PayOrgFee')));
const AutoMappingSuggest = Loadable(lazy(() => import('src/pages/mapping/AutoMappingSuggest')));

// External Page to review the invited service
const ExternalClientView = Loadable(lazy(() => import('src/pages/clientView')));

const routes: RouteObject[] = [
  {
    path: '/authentication',
    children: [
      {
        path: '/authentication/password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: '/authentication/password-reset',
        element: <PasswordReset />
      },
      {
        path: '/authentication/verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: '/client-view/:encryptedCode',
    element: <ExternalClientView />,
  },
  {
    path: '/about',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/about/:domain',
        element: <AboutUs />
      }
    ]
  },
  {
    path: '/acceptinvite',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/acceptinvite',
        element: <AcceptInvite />
      }
    ]
  },
  {
    path: '/fillup',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/fillup',
        element: <Fillup />
      }
    ]
  },
  {
    path: '/payorgfee',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/payorgfee',
        element: <PayOrgFee />
      }
    ]
  },
  {
    path: '/wallet',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/wallet',
        element: <Wallet />
      }
    ]
  },
  {
    path: '/configuration',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/configuration',
        element: <Configuration />
      },
      {
        path: '/configuration/information',
        element: <Configuration />
      },
      {
        path: '/configuration/upgrade',
        element: <Configuration />
      },
      {
        path: '/configuration/externalplatforms',
        element: <Configuration />
      },
      {
        path: '/configuration/profile',
        element: <Configuration />
      },
      {
        path: '/configuration/organization',
        element: <Configuration />
      },
      {
        path: '/configuration/id-verification',
        element: <Configuration />
      },
      {
        path: '/configuration/notification',
        element: <Configuration />
      },
      {
        path: '/configuration/security',
        element: <Configuration />
      },
      {
        path: '/configuration/org/new',
        element: <Configuration />
      },
      {
        path: '/configuration/org/:id',
        element: <Configuration />
      }
    ]
  },
  {
    path: '/marketplace',
    element: (
      <AuthGuard isFree>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/marketplace/organization',
        element: <OrgSearch />
      },
      {
        path: '/marketplace/microservice',
        element: <MicroserviceSearch />
      },
      {
        path: '/marketplace/microservice/service/:serviceId',
        element: <ServiceRequest />
      },
      {
        path: '/marketplace/microservice/service-bundle/:serviceId',
        element: <ServiceRequest />
      },
      {
        path: '/marketplace/asset',
        element: <AssetSearch />
      },
      {
        path: '/marketplace/asset/:assetId',
        element: <AssetRequest />
      }
    ]
  },
  {
    path: '/proposal',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/proposal',
        element: <DirectOffer />
      },
      {
        path: '/proposal/new',
        element: <DirectOffer />
      },
      {
        path: '/proposal/:ticketId',
        element: <DirectOffer />
      }
    ]
  },
  {
    path: '/management',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/management/microservice',
        element: <MicroservicePage />
      },
      {
        path: '/management/microservice/service/new',
        element: <MicroservicePage />
      },
      {
        path: '/management/microservice/service/edit/:serviceId',
        element: <MicroservicePage />
      },
      {
        path: '/management/microservice/service/detail/:serviceId',
        element: <MicroservicePage />
      },
      {
        path: '/management/microservice/service/offer/:serviceId',
        element: <MicroservicePage />
      },
      {
        path: '/management/microservice/service-bundle/new',
        element: <MicroservicePage />
      },
      {
        path: '/management/microservice/service-bundle/edit/:serviceId',
        element: <MicroservicePage />
      },
      {
        path: '/management/microservice/service-bundle/detail/:serviceId',
        element: <MicroservicePage />
      },
      {
        path: '/management/microservice/service-bundle/offer/:serviceId',
        element: <MicroservicePage />
      },
      {
        path: '/management/asset',
        element: <AssetPage />
      },
      {
        path: '/management/asset/new',
        element: <AssetPage />
      },
      {
        path: '/management/asset/edit/:assetId',
        element: <AssetPage />
      },
      {
        path: '/management/asset/detail/:assetId',
        element: <AssetPage />
      },
      {
        path: '/management/structure',
        element: <StructureBoard />
      },
      {
        path: '/management/structure/client',
        element: <StructureBoard />
      },
      {
        path: '/management/structure/supplier',
        element: <StructureBoard />
      },
      {
        path: '/management/structure/member',
        element: <StructureBoard />
      },
      {
        path: '/management/structure/role',
        element: <StructureBoard />
      },
      {
        path: '/management/structure/team',
        element: <StructureBoard />
      },
      {
        path: '/management/templates',
        element: <Templates />
      },
      {
        path: '/management/templates/:option',
        element: <Templates />
      },
      {
        path: '/management/templates/new',
        element: <TemplateEdit />
      },
      {
        path: '/management/templates/edit/:templateId',
        element: <TemplateEdit />
      },
      {
        path: '/management/network',
        element: <NetworkView />
      },
      {
        path: '/management/network/findpartner',
        element: <NetworkView />
      },
      {
        path: '/management/network/partner',
        element: <NetworkView />
      },
      {
        path: '/management/network/partner/:orgId',
        element: <NetworkView />
      },
      {
        path: '/management/network/referral',
        element: <NetworkView />
      },
      {
        path: '/management/network/referral/:referralId',
        element: <NetworkView />
      },
      {
        path: '/management/network/contact',
        element: <NetworkView />
      },
      {
        path: '/management/network/contact/import',
        element: <NetworkView />
      },
      {
        path: '/management/file',
        element: <MyFiles />
      },
      {
        path: '/management/assign',
        element: <AssignPad />
      },
      {
        path: '/management/assign/deal',
        element: <AssignPad />
      },
      {
        path: '/management/assign/service',
        element: <AssignPad />
      },
      {
        path: '/management/assign/asset',
        element: <AssignPad />
      },
      {
        path: '/management/assign/file',
        element: <AssignPad />
      },
      {
        path: '/management/assign/referral',
        element: <AssignPad />
      },
      {
        path: '/management/assign/partner',
        element: <AssignPad />
      }
    ]
  },
  {
    path: '/deal',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/deal/sale',
        element: <Ticket />
      },
      {
        path: '/deal/sale/detail/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/sale/service-bundle/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/sale/service/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/purchase',
        element: <Ticket />
      },
      {
        path: '/deal/purchase/detail/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/purchase/service-bundle/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/purchase/service/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/referral',
        element: <Ticket />
      },
      {
        path: '/deal/referral/detail/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/referral/service-bundle/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/referral/service/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/autodebit',
        element: <Ticket />
      },
      {
        path: '/deal/autodebit/detail/:ticketId',
        element: <Ticket />
      }
    ]
  },
  {
    path: '/communication',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/communication/external',
        element: <Chat />
      },
      {
        path: '/communication/external/:threadKey',
        element: <Chat />
      },
      {
        path: '/communication/internal',
        element: <Chat />
      },
      {
        path: '/communication/internal/:threadKey',
        element: <Chat />
      }
    ]
  },
  {
    path: '/bs',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/bs',
        element: <BusinessSearch />
      },
      {
        path: '/bs/search',
        element: <BusinessSearch />
      },
      {
        path: '/bs/anzsicsuggestion',
        element: <BusinessSearch />
      },
      {
        path: '/bs/favourites',
        element: <BusinessSearch />
      }
    ]
  },
  {
    path: '/home',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/home',
        element: <Dashboard />
      }
    ]
  },
  {
    path: '/analysis',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/analysis',
        element: <Diagram />
      }
    ]
  },
  {
    path: '/',
    children: [
      {
        path: '/temp',
        element: (
          <AuthGuard isFree>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '/temp',
            element: <OrgCreatePad />
          }
        ]
      },
      {
        path: '/',
        element: (
          <AuthGuard isFree>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '/',
            element: <Dashboard />
          }
        ]
      },
      {
        path: '/login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '/register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: '/register/:code',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      }
    ]
  },
  {
    path: '/mapping',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [{
      path: '/mapping/:platform',
      element: <AutoMappingSuggest />
    }]
  },
  {
    path: '/',
    element: (
      <AuthGuard isFree>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [{
      path: '/*',
      element: <ErrorPage />
    }]
  }
];

export default routes;
