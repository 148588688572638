import axios from 'axios';
import { null2empty } from 'src/globals';
import { getCountry } from './countries';

export const getIpAddress = async () => {
  const res = await axios.get('https://api.ipify.org?format=json');
  return res.data.ip;
};

export const getAddress = async () => {
  const res = (await axios.get('https://ipapi.co/json/')).data;
  return {
    addressLine1: `${res.city} ${res.region}, ${res.country_name}`,
    city: null2empty(res.city),
    region: null2empty(res.region),
    postalCode: null2empty(res.postal),
    country: null2empty(res.country_code),
    phone: null2empty(res.country_calling_code)
  };
};

export const getClientAddress = async () => {
  try {
    const address = await getAddress();
    return address;
  } catch (err) {
    console.log(JSON.stringify(err));
    return null;
  }
};

const getGeoLocationByBrowser = () => new Promise((resolve, reject) => (
  navigator.geolocation.getCurrentPosition(resolve, reject, {})
));

export const getGeoLocation = async () => {
  const info = (await getGeoLocationByBrowser()) as any;
  return {
    latitude: info.coords.latitude,
    longitude: info.coords.longitude
  };
};

export const getAddressFromContact = (contact) => {
  if (!contact) return null;
  let foundAddr = null;
  contact.addresses.forEach((addr) => {
    if (!addr.addressLine1) return;
    if (contact.addressType === 'PHYSICAL' && (!foundAddr || foundAddr.addressType !== 'PHYSICAL')) {
      foundAddr = addr;
    } else if (contact.addressType === 'POBOX' && (!foundAddr || (foundAddr.addressType !== 'PHYSICAL' && foundAddr.addressType !== 'POBOX'))) {
      foundAddr = addr;
    } else if (!foundAddr) {
      foundAddr = addr;
    }
  });
  return foundAddr;
};

export const getAddressFromUser = (u) => {
  let ret: string = '';
  if (u.city?.length + u.country?.length <= 0) return null;
  ret = u.city;
  if (u.country?.length > 0) {
    if (ret.length > 0) {
      ret = `${ret}, ${getCountry(u.country)?.text}`;
    } else {
      ret = getCountry(u.country)?.text;
    }
  }
  return ret;
};

export const getEmailFromContact = (contact) => {
  if (!contact) return null;
  let foundEmail = null;
  contact.emails.forEach((email) => {
    if (!email.emailAddress) return;
    if (email.reason === 'DEFAULT' && (!foundEmail || foundEmail.reason !== 'DEFAULT')) {
      foundEmail = email;
    } else if (email.reason === 'ALTERNATIVE' && (!foundEmail || (foundEmail.reason !== 'DEFAULT' && foundEmail.reason !== 'ALTERNATIVE'))) {
      foundEmail = email;
    } else if (!foundEmail) {
      foundEmail = email;
    }
  });
  return foundEmail;
};
