import { DAY_MILISECONDS, REQUEST_ONE_OFF, REQUEST_CUSTOM, periodLengthList, MINUTE_MILISECONDS, LENGTH_MONTH, LENGTH_YEAR, LENGTH_WEEK } from 'src/globals';
import { toOrdinal } from 'number-to-words';

export const getPeriodOffsetDays = (from: Date | string, to: Date | string, period: number, offset: number): string[] => {
  const st = new Date(from).getTime();
  const ed = new Date(to).getTime();
  const periodMs = period * DAY_MILISECONDS;
  const offsetMs = offset * DAY_MILISECONDS;
  const arr = [];
  for (let pst = st + offsetMs; pst <= ed; pst += periodMs) {
    arr.push(new Date(pst).toISOString());
  }
  return arr;
};

export const getPeriodOffsetDaysByCount = (from: Date | string, to: Date | string, period: number, offset: number): string[] => {
  const arr = [];
  const st = new Date(from).getTime();
  const ed = new Date(to).getTime() + DAY_MILISECONDS;
  const offsetMs = offset * DAY_MILISECONDS;
  if (period !== LENGTH_MONTH && period !== LENGTH_YEAR) {
    const periodMs = periodLengthList.find((lengthItem) => lengthItem.type === period)?.days * DAY_MILISECONDS;
    const totalCount = Math.floor((ed - st + 1) / periodMs);
    for (let i = 0; i < totalCount; i++) {
      arr.push(new Date(st + offsetMs + i * periodMs).toISOString());
    }
  } else {
    const totalCount = Math.round((ed - st) / DAY_MILISECONDS / (period === LENGTH_MONTH ? 365 / 12 : 365));
    const periodMs = (ed - st) / totalCount;
    for (let i = 0; i < totalCount; i++) {
      arr.push(new Date(st + Math.round(i * periodMs)).toISOString());
    }
  }
  if (arr.length === 0) arr.push(new Date(to).toISOString());
  return arr;
};

export const getPeriodOffsetFromDate = (from: Date | string, period: number): string => {
  const fromDate = new Date(from);
  if (period === LENGTH_WEEK) {
    const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return weekday[fromDate.getDay()];
  }
  if (period === LENGTH_MONTH) {
    const d = fromDate.getDate();
    if (d === new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0).getDate()) return 'Last Day';
    if (d === 1) return 'First Day';
    if (d === 15) return 'Middle Day';
    return `${toOrdinal(d)} Day`;
  }
  return null;
};

export const getPeriodicalDatesByCount = (from: Date | string, to: Date | string, period: number, count: number): string[] => {
  const arr = [];
  const st = new Date(from).getTime();
  const ed = new Date(to).getTime() + DAY_MILISECONDS;
  if (period !== LENGTH_MONTH && period !== LENGTH_YEAR) {
    const periodMs = periodLengthList.find((lengthItem) => lengthItem.type === period).minutes * MINUTE_MILISECONDS;
    const totalCount = Math.floor((ed - st + 1) / periodMs) * count;
    for (let i = 0; i < totalCount; i++) {
      arr.push(new Date(st + Math.round((i * periodMs) / count)).toISOString());
    }
  } else {
    const totalCount = count * Math.round((ed - st) / DAY_MILISECONDS / (period === LENGTH_MONTH ? 365 / 12 : 365));
    const periodMs = (ed - st) / totalCount;
    for (let i = 0; i < totalCount; i++) {
      arr.push(new Date(st + Math.round(i * periodMs)).toISOString());
    }
  }
  return arr;
};

export const getTimeMovedByDuration = (startDate: Date, duration: any): Date => {
  let endDate = new Date(startDate);
  if (duration.type !== LENGTH_MONTH && duration.type !== LENGTH_YEAR) {
    endDate = new Date(endDate.getTime() + Math.ceil((periodLengthList.find((lengthItem) => lengthItem.type === duration.type).minutes * duration.length * MINUTE_MILISECONDS) / DAY_MILISECONDS) * DAY_MILISECONDS);
  } else if (duration.type === LENGTH_MONTH) {
    endDate.setMonth(endDate.getMonth() + duration.length);
  } else {
    endDate.setFullYear(endDate.getFullYear() + duration.length);
  }
  return endDate;
};

export const getFormatedPeriodInfo = (info: any, duration: any) => {
  if (info.type === REQUEST_ONE_OFF || info.type === REQUEST_CUSTOM) {
    const startDate = new Date(info.start);
    const endDate = getTimeMovedByDuration(startDate, info.type === REQUEST_ONE_OFF ? duration : { type: info.per, length: info.periodCount });
    return {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    };
  }
  const monthToNum = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12
  };
  const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const year = Number(info.year.substring(0, 4));
  if ((year + 1) % 400 === 0 || ((year + 1) % 100 !== 0 && (year + 1) % 4 === 0)) monthDays[1]++;
  let startMonth = 1;
  let endMonth = 1;
  const crossIdx = info.period.indexOf('-');
  if (crossIdx > 0) {
    startMonth = monthToNum[info.period.substring(0, crossIdx).trim()];
    endMonth = monthToNum[info.period.substring(crossIdx + 1).trim()];
  } else {
    startMonth = monthToNum[info.period.trim()];
    endMonth = startMonth;
  }
  return {
    startDate: new Date(`${startMonth}/01/${year + (startMonth < 7 ? 1 : 0)}`).toISOString(),
    endDate: new Date(`${endMonth}/${monthDays[endMonth - 1]}/${year + (endMonth < 7 ? 1 : 0)}`).toISOString()
  };
};

export const getLifeTimeString = (duration: any): string => {
  const strSuffix = periodLengthList.find((lengthItem) => lengthItem.type === duration.type).text;
  if (duration.length === 1) return `1 ${strSuffix}`;
  return `${duration.length} ${strSuffix}s`;
};
