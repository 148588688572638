import { FC } from 'react';
import {
  Box,
  Typography
} from '@material-ui/core';
import {
  CRYPTO_ETHER,
  CRYPTO_TETHER,
  CRYPTO_MRC,
  FIAT_AUD,
  FIAT_USD,
  tokenData
} from 'src/globals';
import { CryptoWalletIcon, FiatIcon } from 'src/pages/wallet/WalletSideBar';
import PropTypes from 'prop-types';

const CurrencyItem: FC<{ type: string; selected?: boolean; }> = ({ type, selected }) => {
  if (type === CRYPTO_ETHER || type === CRYPTO_TETHER || type === CRYPTO_MRC) {
    const data = tokenData.find((v) => v.name === type);
    return (
      <Box
        display="flex"
        alignItems="center"
      >
        <CryptoWalletIcon type={type} />
        <Typography
          sx={{
            ml: 2,
            color: selected ? 'text.primary' : data.color
          }}
          noWrap
        >
          {selected ? data.title : data.unit}
        </Typography>
      </Box>
    );
  }
  if (type === FIAT_AUD) {
    return (
      <Box
        display="flex"
        alignItems="center"
      >
        <FiatIcon type={type} />
        <Typography
          sx={{
            color: selected ? 'text.primary' : '#A349A2',
            ml: 2,
            textAlign: 'left'
          }}
        >
          {selected ? 'AUD (Australian Dollar)' : FIAT_AUD}
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <FiatIcon type={type} />
      <Typography
        sx={{
          textAlign: 'left',
          ml: 2,
          color: 'text.primary'
        }}
      >
        {selected ? 'USD (US Dollar)' : FIAT_USD}
      </Typography>
    </Box>
  );
};

CurrencyItem.propTypes = {
  type: PropTypes.string,
  selected: PropTypes.bool
};

export default CurrencyItem;
