import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { Contact } from 'src/../../Common/Model/contact';

interface ContactState {
  contacts: Contact[];
  contactsNeedReload: boolean;
  connectState: { qbo: string; xero: string; contactAutoMapping: boolean },
  connectStateNeedReload: boolean;
  syncState: { qbo: boolean; xero: boolean; lodgeit: boolean },
  syncStateNeedReload: boolean;
  contactToUsers: any[];
  curPage: number;
  totalCount: number;
}

const initialState: ContactState = {
  contacts: [],
  contactsNeedReload: true,
  connectState: { qbo: null, xero: null, contactAutoMapping: false },
  connectStateNeedReload: true,
  syncState: { qbo: false, xero: false, lodgeit: false },
  syncStateNeedReload: true,
  contactToUsers: [],
  curPage: 1,
  totalCount: 0
};

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    clearState(state: ContactState) {
      state.contacts.splice(0, state.contacts.length);
      state.contactsNeedReload = true;
      state.connectState = { qbo: null, xero: null, contactAutoMapping: false };
      state.connectStateNeedReload = true;
      state.syncState = { qbo: false, xero: false, lodgeit: false };
      state.syncStateNeedReload = true;
      state.contactToUsers.splice(0, state.contactToUsers.length);
    },
    setContacts(state: ContactState, action: PayloadAction<Contact[]>): void {
      state.contacts = action.payload as Contact[];
      state.contactsNeedReload = false;
    },
    setCurPage(state: ContactState, action: PayloadAction<number>): void {
      state.curPage = action.payload as number;
    },
    setTotalCount(state: ContactState, action: PayloadAction<number>): void {
      state.totalCount = action.payload as number;
    },

    setContactsNeedReload(state: ContactState, action: PayloadAction<boolean>): void {
      state.contactsNeedReload = action.payload;
    },
    addContact(state: ContactState, action: PayloadAction<Contact>): void {
      state.contacts.push(action.payload);
    },
    updateContact(state: ContactState, action: PayloadAction<Contact>): void {
      const contact = action.payload;
      state.contacts = state.contacts.map((cont) => (cont.id === contact.id ? contact : cont));
    },
    deleteContact(state: ContactState, action: PayloadAction<string>): void {
      const contactId = action.payload;
      state.contacts = state.contacts.filter((contact) => contact.id !== contactId);
    },
    setConnectState(state: ContactState, action: PayloadAction<any>): void {
      const { connectedQbo, connectedXero, contactAutoMapping } = action.payload;
      state.connectState.qbo = connectedQbo;
      state.connectState.xero = connectedXero;
      state.connectState.contactAutoMapping = contactAutoMapping;
      state.connectStateNeedReload = false;
    },
    setConnectStateNeedReload(state: ContactState, action: PayloadAction<boolean>): void {
      state.connectStateNeedReload = action.payload;
    },
    setSyncState(state: ContactState, action: PayloadAction<any>): void {
      const { syncQbo, syncXero } = action.payload;
      state.syncState.qbo = syncQbo;
      state.syncState.xero = syncXero;
      state.syncStateNeedReload = false;
    },
    setSyncStateNeedReload(state: ContactState, action: PayloadAction<boolean>): void {
      state.syncStateNeedReload = action.payload;
    },
    addContactToUsers(state: ContactState, action: PayloadAction<any>): void {
      const { contactId, userInfos } = action.payload;
      if (state.contactToUsers.filter((data) => data.cid === contactId).length < 1) state.contactToUsers.push({ cid: contactId, userData: userInfos });
    }
  }
});

export const { reducer } = slice;

export const clearContactState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setContacts = (cs: Contact[]) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setContacts(cs));
};

export const setCurPage = (cs: number) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setCurPage(cs));
};

export const setTotalCount = (cs: number) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setTotalCount(cs));
};

export const setContactsNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setContactsNeedReload(needReload));
};

export const addContact = (c: Contact) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.addContact(c));
};

export const updateContact = (c: Contact) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateContact(c));
};

export const deleteContact = (contactId: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.deleteContact(contactId));
};

export const setConnectState = (_connectedQbo: string, _connectedXero: string, contactAutoMapping: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setConnectState({ connectedQbo: _connectedQbo, connectedXero: _connectedXero, contactAutoMapping }));
};

export const setSyncState = (_syncQbo: boolean, _syncXero: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setSyncState({ syncQbo: _syncQbo, syncXero: _syncXero }));
};

export const setConnectStateNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setConnectStateNeedReload(needReload));
};

export const setSyncStateNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setSyncStateNeedReload(needReload));
};

export const addContactToUsers = (_cId: string, _userData: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.addContactToUsers({ contactId: _cId, userInfos: _userData }));
};

export default slice;
