export const amplifyConfig = {
  aws_project_region: 'ap-southeast-2',
  aws_cognito_identity_pool_id: 'ap-southeast-2:d698958f-85fe-4b84-80a0-08641b4ff90a',
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools_id: 'ap-southeast-2_MWyIPtOb6',
  aws_user_pools_web_client_id: '5filq03kqveh2j4tkchc296fo2',
  oauth: {
    domain: 'accziom.auth.ap-southeast-2.amazoncognito.com',
    scope: [
      'email',
      'openid',
      'profile',
    ],
    redirectSignIn: process.env.NODE_ENV === 'production' ? 'https://go.accziom.com/home' : 'http://localhost:3000/home',
    redirectSignOut: process.env.NODE_ENV === 'production' ? 'https://go.accziom.com' : 'http://localhost:3000',
    responseType: 'code'
  },
  federationTarget: 'COGINTO_USER_POOLS',
  cookieStorage: {
    secure: false,
    domain: process.env.NODE_ENV === 'production' ? 'accziom.com' : 'localhost',
    sameSite: 'strict'
  }
};

export const AWS_REGION = 'ap-southeast-2';

export const accsiomURIs = {
  hpcalc_api_domain: 'https://www.wolframcloud.com/obj/4c1e027f-6fa7-4722-a0b9-aea08562d14a',
  hpanal_api_domain: 'https://www.wolframcloud.com/obj/4d216c6a-2a69-4716-81f7-469f83b1ead7',
  lifeexp_api_domain: 'http://ec2-52-78-11-78.ap-northeast-2.compute.amazonaws.com/wolfram/index.php',
  superann_api_domain: 'https://www.wolframcloud.com/obj/74b0c06c-5c3a-40f7-9c97-bf171df95a0e',
  websocket_url: 'wss://bsearchau.accziom.com:8018',
  map_image_domain: 'https://www.wolframcloud.com/obj/f0c13bbc-7cb8-4158-8112-41502c095272',
  os_uri: 'https://api.accziom.com/v1/orgQuery',
  bs_uri: 'https://bsearchau.accziom.com',
  validate_human_uri: 'https://api.accziom.com/v1/validateHuman',
  microservice_uri: 'https://api.accziom.com/v1/microservices',
  request_uri: 'https://api.accziom.com/v1/requests',
  asset_uri: 'https://api.accziom.com/v1/assets',
  channel_uri: 'https://api.accziom.com/v1/channels',
  sync_connect_uri: 'https://api.accziom.com/sync/connect',
  sync_material_uri: 'https://api.accziom.com/sync/sync-material',
  show_material_uri: 'https://api.accziom.com/sync/show-material',
  import_sample_file_uri: 'https://accziom-public-asset.s3.ap-southeast-2.amazonaws.com/Accziom_Contacts_Template.xlsx',
  notification_websocket_url: 'https://notifications.accziom.com/',
  // twilio_notification_url: process.env.NODE_ENV === 'production' ? 'https://notifications.accziom.com/twilio' : 'http://localhost:3030/twilio',
  twilio_notification_url: 'https://notifications.accziom.com/twilio',
  mrc_websocket_url: 'wss://bsearchau.accziom.com:8887',
  aws_filethread_url: 'https://accziom-filethread.s3.ap-southeast-2.amazonaws.com',
  ipfs_filethread_url: 'https://gateway.ipfs.io/ipfs',
  apprising_ws_url: 'https://wsserver.accziom.com/',
  org_banner_uri: 'https://api.accziom.com/v1/banner',
  orgaboutus_uri: 'https://api.accziom.com/v1/orgaboutus',
  addresshelper_uri: 'https://7dxjjfw2m6zs3rp65eyv4cnqua0wsjhf.lambda-url.ap-southeast-2.on.aws/'
};

export const googleMapApiKey = 'AIzaSyA8eVticKgtNJmpl6n-HxPAF5WIz65eTqQ';

export const QBO_URL_PREFIX = {
  PRODUCTION_APP: 'https://app.qbo.intuit.com/app',
  SANDBOX_APP: 'https://app.sandbox.qbo.intuit.com/app'
};
export const XERO_DOMAIN = 'https://go.xero.com';

export const mrcPort = 8886;

export const bsPort = 8007;

// old org cloudfront E2U2D826WTXP77
// old org bucket accziom-org
