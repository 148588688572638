import { Contact } from 'src/../../Common/Model/contact';
import {
  lambdaContactConnectGetStatus,
  lambdaContactSyncDisable,
  lambdaContactSyncDisconnect,
  lambdaContactSyncGetStatus,
  lambdaCreateNewContact,
  lambdaDeleteContact,
  lambdaLoadAllContacts,
  lambdaSyncLodgeit,
  lambdaUpdateContact,
  lambdaLoadContactsWithPagination
} from 'src/aws/lambdaDispatch';

class ContactApi {
  createContact(contact: any): Promise<any> {
    return new Promise((resolve, reject) => {
      lambdaCreateNewContact(contact)
        .then((id) => {
          resolve(id);
        })
        .catch((err) => {
          reject(new Error(`New contact error: ${JSON.stringify(err)}`));
        });
    });
  }

  updateContact(contact: any): Promise<number> {
    return new Promise((resolve, reject) => {
      lambdaUpdateContact(contact)
        .then((id) => resolve(id))
        .catch((err) => reject(new Error(`Update contact error: ${JSON.stringify(err)}`)));
    });
  }

  deleteContact(contactInfo: any): Promise<number> {
    return new Promise((resolve, reject) => {
      lambdaDeleteContact(contactInfo)
        .then((id) => resolve(id))
        .catch((err) => reject(new Error(`Delete contact error: ${JSON.stringify(err)}`)));
    });
  }

  loadAllContacts(uid: string): Promise<Contact[]> {
    return new Promise((resolve, reject) => {
      lambdaLoadAllContacts(uid)
        .then((contacts) => resolve(contacts))
        .catch((err) => reject(new Error(`Loading contact error: ${JSON.stringify(err)}`)));
    });
  }

  loadContactsWithPagination(uid: string, page: number, limit: number, query?: string, isIndividual?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      lambdaLoadContactsWithPagination(uid, page, limit, query, isIndividual)
        .then((contacts) => resolve(contacts))
        .catch((err) => reject(new Error(`Loading contact error: ${JSON.stringify(err)}`)));
    });
  }

  syncLodgeit(uid: string) {
    return new Promise((resolve) => {
      lambdaSyncLodgeit(uid)
        .then((data) => resolve(data))
        .catch(() => resolve({ statusCode: 504 }));
    });
  }

  getSyncStatus(uid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      lambdaContactSyncGetStatus(uid)
        .then((res) => resolve(res))
        .catch((err) => reject(new Error(`Get contact sync status error: ${JSON.stringify(err)}`)));
    });
  }

  getConnectStatus(uid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      lambdaContactConnectGetStatus(uid)
        .then((res) => resolve(res))
        .catch((err) => reject(new Error(`Get qbo and xero connection status error: ${JSON.stringify(err)}`)));
    });
  }

  disConnectPlatform(uid: string, platform): Promise<boolean> {
    return new Promise((resolve) => {
      lambdaContactSyncDisconnect(uid, platform)
        .then(() => resolve(true))
        .catch((e) => {
          console.log(`Disconnect platform failed with error : , ${JSON.stringify(e)}`);
          resolve(false);
        });
    });
  }

  disableSyncPlatform(uid: string, platform): Promise<boolean> {
    return new Promise((resolve) => {
      lambdaContactSyncDisable(uid, platform)
        .then(() => resolve(true))
        .catch((e) => {
          console.log(`Disable synchronization failed with error : , ${JSON.stringify(e)}`);
          resolve(false);
        });
    });
  }
}

export const contactApi = new ContactApi();
