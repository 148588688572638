import { FC, useState } from 'react';
import { Box } from '@material-ui/core';
import ActionButton from 'src/components/interact/ActionButton';
import SyncIcon from '@mui/icons-material/Sync';
import { getGasFee } from 'src/utils/merchantUtils';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import NumberField from 'src/components/interact/NumberField';

const GasLimit: FC<{
  gasLimit: number;
  onSetGasLimit: any;
  estimateDisabled?: boolean;
  btcAddress: string;
  amount: number;
}> = ({ gasLimit, onSetGasLimit, estimateDisabled, btcAddress, amount }) => {
  const [isEstimatingGasFee, setEstimatingGasFEe] = useState<boolean>(false);

  const onEstimateGasFee = async () => {
    setEstimatingGasFEe(true);
    try {
      const gf = await getGasFee({
        type: 'sending',
        btc_address: btcAddress,
        amount,
        mrc_tx: 'TEST_ESTIMATE'
      });
      onSetGasLimit(gf);
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error('Failed to estimate gas fee!');
    } finally {
      setEstimatingGasFEe(false);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <NumberField
        size="small"
        label="Satoshi Gas Fee *"
        value={gasLimit}
        onNumChange={(newValue) => {
          if (newValue === null) onSetGasLimit(null);
          else {
            if (newValue < 0) newValue = 0;
            onSetGasLimit(newValue);
          }
        }}
        error={!gasLimit}
        sx={{
          width: 180
        }}
      />
      <Box
        pl={1}
      >
        <ActionButton
          title="Estimate Satoshi Gas Fee"
          icon={SyncIcon}
          onClick={onEstimateGasFee}
          isLoading={isEstimatingGasFee}
          color="action"
          disabled={estimateDisabled}
        />
      </Box>
    </Box>
  );
};

GasLimit.propTypes = {
  gasLimit: PropTypes.number,
  onSetGasLimit: PropTypes.func,
  estimateDisabled: PropTypes.bool,
  btcAddress: PropTypes.string,
  amount: PropTypes.number
};

export default GasLimit;
