import { useRef, useState } from 'react';
import type { FC } from 'react';
import {
  Box,
  Popover,
  Badge,
  Typography,
  MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { tenary } from 'src/globals';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import useMounted from 'src/hooks/useMounted';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface SubItem {
  title: string;
  href?: string;
  onClick?: any;
  icon?: any;
  value?: number;
}
export interface Item {
  title: string;
  href?: string;
  onClick?: any;
  icon?: any;
  authRequired?: number;
  items?: SubItem[];
}

interface NavMenuItemProps {
  data: Item;
  isHorizontal: boolean;
  value?: number;
}

const NavMenuItem: FC<NavMenuItemProps> = (props) => {
  const navigate = useNavigate();
  const mounted = useMounted();
  const { pathname } = useLocation();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { data, isHorizontal, value } = props;
  const { icon: Icon, title, href, onClick, items } = data;
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('normal');

  const isSamePath = (path: string): boolean => {
    if (!path || ['/', '/home', '/login'].includes(path)) return false;
    return (!!matchPath({
      path,
      caseSensitive: false,
      end: false
    }, pathname));
  };
  const selected = !!href && isSamePath(href);

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Box
        ref={anchorRef}
        height="100%"
        display="flex"
        flexDirection={isHorizontal ? 'row' : 'column'}
        justifyContent="center"
        alignItems="center"
        onMouseEnter={(event) => {
          event.stopPropagation();
          setStatus('hover');
        }}
        onMouseLeave={(event) => {
          event.stopPropagation();
          setStatus('normal');
        }}
        onMouseDown={(event) => {
          event.stopPropagation();
          setStatus('down');
        }}
        onMouseUp={(event) => {
          event.stopPropagation();
          if (status !== 'down') return;
          if (items?.length > 0) {
            setOpen(true);
            setStatus('normal');
          } else {
            setStatus('normal');
            onClick?.();
            if (href) navigate(href);
          }
        }}
        style={{
          cursor: 'pointer'
        }}
        sx={{
          py: isHorizontal ? 0.5 : 1,
          px: isHorizontal ? 1.5 : 1,
          borderRadius: 1,
          position: 'relative',
          transition: 'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '::after': {
            content: '""',
            width: '100%',
            height: '100%',
            backgroundColor: selected ? '#00000020' : '#00000000',
            position: 'absolute',
            left: '0px',
            top: '0px',
            zIndex: '-1',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderRadius: 1
          },
          ...(selected && { boxShadow: '0px 1px 2px #0a164626' })
        }}
      >
        <Badge
          badgeContent={
            value !== 0 ? (
              <Typography
                variant="subtitle1"
                style={{
                  marginTop: '-2px'
                }}
              >
                {value}
              </Typography>
            ) : value
          }
          variant="dot"
          color="error"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box
            sx={{
              height: isHorizontal ? 20 : 24,
              width: isHorizontal ? 20 : 24,
              backgroundColor: 'transparent',
              color: href === '/proposal' ? 'primary.main' : 'text.secondary',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              mr: isHorizontal ? 0.5 : 0
            }}
          >
            <Icon
              style={{
                height: status === 'hover' ? tenary(isHorizontal, '18px', '20px') : tenary(isHorizontal, '20px', '24px'),
                width: status === 'hover' ? tenary(isHorizontal, '18px', '20px') : tenary(isHorizontal, '20px', '24px')
              }}
            />
          </Box>
        </Badge>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            '& *': {
              color: href === '/proposal' ? 'primary.main' : 'text.secondary'
            }
          }}
        >
          <Box
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '14px',
              whiteSpace: 'nowrap',
              textDecoration: status === 'hover' ? 'underline' : 'none'
            }}
          >
            {title}
          </Box>
          {items.length > 0 && (
            <ArrowDropDownIcon
              sx={{
                width: 21,
                height: 21,
                mt: '-3px',
                ml: '-2px',
                transition: '.3s',
                transform: `rotate(${open ? 180 : 0}deg)`
              }}
            />
          )}
        </Box>
      </Box>
      {items?.length > 0 && (
        <Popover
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom'
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }}
          keepMounted
          onClose={handleClose}
          open={open}
          PaperProps={{
            sx: {
              minWidth: 200,
              overflow: 'hidden',
              maxHeight: 'calc(100% - 100px)',
              mt: 1
            }
          }}
        >
          {items.map((item) => {
            const SubIcon = item.icon as any;
            return (
              <MenuItem
                key={item.title}
                onClick={(event) => {
                  event.stopPropagation();
                  item.onClick?.();
                  if (item.href) navigate(item.href);
                  if (mounted.current) handleClose();
                }}
                sx={{
                  px: 3,
                  py: 1.5
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <SubIcon
                    style={{
                      width: 21,
                      height: 21
                    }}
                  />
                  {!item.value ? (
                    <Typography
                      variant="body1"
                      pl={1}
                      mt={0.5}
                    >
                      {item.title}
                    </Typography>
                  ) : (
                    <Badge
                      variant="dot"
                      color="error"
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    >
                      <Typography
                        variant="body1"
                        pl={1}
                        mt={0.5}
                      >
                        {item.title}
                      </Typography>
                    </Badge>
                  )}
                </Box>
              </MenuItem>
            );
          })}
        </Popover>
      )}
    </>
  );
};

NavMenuItem.propTypes = {
  data: PropTypes.any,
  isHorizontal: PropTypes.bool,
  value: PropTypes.number
};

export default NavMenuItem;
