import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, IconButton, Input, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid } from '@material-ui/core';
import { BootstrapDialog, BootstrapDialogTitle } from 'src/components/dialog/BootstrapDialog';
import useMounted from 'src/hooks/useMounted';
import { CheckCircle, Close } from '@material-ui/icons';
import { shortId } from 'src/types/microservice';
import CryptoWallet from 'src/icons/CryptoWallet';
import Scrollbar from 'src/components/layout/Scrollbar';
import { useDispatch, useSelector } from 'src/store';
import { lambdaGetWalletById, lambdaSearchWallet, lambdaUpdateRecentClients, lambdaGetUserMinInfos, lambdaMercTransfer } from 'src/aws/lambdaDispatch';
import { Wallet } from 'src/../../Common/Model/wallet';
import toast from 'react-hot-toast';
import { setTransactions, setWalletInfo } from 'src/slices/wallet';
import { UserMinInfo } from 'src/../../Common/Model/common';
import SmallUserInfo from 'src/components/SmallUserInfo';
import { updateUserMinInfos } from 'src/slices/organization';
import { ACCZIOM_CLIENT, ACCZIOM_ORG, BACKGROUND_DEEP, CRYPTO_MRC, tenary } from 'src/globals';
import CheckLabel from 'src/components/CheckLabel';
import GasLimit from './GasLimit';
import { getActiveInfo, getUserMinInfoByID } from 'src/utils/getActiveOrgInfo';
import { v4 as uuidv4 } from 'uuid';
import { getRealInviteeId, getRealInviteeInfo } from '../dialog/InviteDialog';
import NumberField from 'src/components/interact/NumberField';

interface SendDlgProps {
  open: boolean;
  onClose: () => void;
  layer2: boolean;
}

export interface UserWallet {
  userMinInfo: UserMinInfo;
  wallet: Wallet | null;
}

const MERcTransferDlg: FC<SendDlgProps> = (props) => {
  const { open, onClose, layer2 } = props;
  const mounted = useMounted();
  // const navigate = useNavigate();
  const [to, setTo] = useState<string | UserWallet>('');
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState<number>(0);
  const { wallet } = useSelector((state) => state.wallet);
  const [isAvailable, setAvailable] = useState(false);
  const [results, setResults] = useState<UserWallet[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [isSendTotal, setSendTotal] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [isChecking, setChecking] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [gasLimit, setGasLimit] = useState<number>(1);
  const { userMinInfos } = useSelector((state) => state.organization);
  const activeInfo = getActiveInfo(ACCZIOM_CLIENT, ACCZIOM_ORG);
  const { balances, transactions } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  const amountError = amount === null || (!layer2 && gasLimit > 0 && amount <= gasLimit);

  const updateRecentClients = async () => {
    if (typeof to === 'string') return;
    let clients = [...wallet.recentClients];
    if (clients.findIndex((client) => client.id === getRealInviteeId(to.userMinInfo)) > -1) {
      // eslint-disable-next-line no-nested-ternary, no-confusing-arrow
      clients.sort((x, y) => (x.id === getRealInviteeId(to.userMinInfo) ? -1 : (y.id === getRealInviteeId(to.userMinInfo) ? 1 : 0)));
    } else {
      clients = [getRealInviteeInfo(to.userMinInfo)].concat(clients);
      if (clients.length > 8) {
        clients.splice(8);
      }
    }
    try {
      const res = await lambdaUpdateRecentClients(wallet.walletID.id, clients);
      if (res) {
        const w = {
          ...wallet,
          recentClients: clients
        };
        dispatch(setWalletInfo(w));
        dispatch(updateUserMinInfos([to.userMinInfo]));
      }
    } catch (err) {
      console.log(JSON.stringify(err));
    }
  };

  const handleToggleSendTotal = async () => {
    setSendTotal(!isSendTotal);
    if (!isSendTotal) {
      if (balances.mrc) {
        setAmount(layer2 ? balances.mrc.layer2 : balances.mrc.layer1);
      }
    } else setAmount(0);
    document.getElementById('Amount')?.focus();
  };

  const handleDone = async () => {
    setSubmitting(true);
    try {
      const res = await lambdaMercTransfer(
        activeInfo,
        typeof to === 'string' ? to : getRealInviteeInfo(to.userMinInfo),
        amount,
        layer2,
        gasLimit
      );
      if (res && !res.errorMessage) {
        dispatch(setTransactions([{
          uid: uuidv4(),
          fromId: activeInfo,
          toId: typeof to === 'string' ? null : getRealInviteeInfo(to.userMinInfo),
          from: wallet.address,
          to: typeof to === 'string' ? to : to.wallet.address,
          fee: -amount,
          event: 'transfer',
          time: new Date().toISOString(),
          ledger: layer2 ? '2' : '1',
          txn: {},
          status: layer2 ? tenary(res.status, 'Completed', 'Failed') : 'Pending'
        }, ...transactions]));
        if (typeof to !== 'string') dispatch(updateUserMinInfos([to.userMinInfo]));
        await updateRecentClients();
        toast.success('The transaction has been successfully queued.');
        onClose();
      } else {
        toast.error('Failed to transfer!');
      }
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error('Failed to transfer!');
    } finally {
      if (mounted.current) {
        setSubmitting(false);
      }
    }
  };

  const handleChangeAmount = (e) => {
    if (e.target.value === '') {
      setAmount(null);
      setSendTotal(false);
      return;
    }
    if (!Number.isNaN(Number(e.target.value))) {
      let nv = Number(e.target.value);
      if (Number.isNaN(nv) || nv < 0) nv = 0;
      const totalValue = layer2 ? balances.mrc.layer2 : balances.mrc.layer1;
      if (nv > totalValue) nv = totalValue;
      setAmount(e.target.value === '' ? null : nv);
      setSendTotal(nv === totalValue);
    }
  };

  const checkTo = async (userMinInfo: UserMinInfo) => {
    setAvailable(false);
    setChecking(true);
    const res = await lambdaGetWalletById({ id: userMinInfo.uid, type: userMinInfo.type }, CRYPTO_MRC);
    if (mounted.current) {
      setChecking(false);
      if (res) {
        // if (typeof to !== 'string') {
        setTo({
          userMinInfo,
          wallet: res
        });
        // }
        setAvailable(true);
      } else {
        setAvailable(false);
        setTo('');
        toast.error(`Selected ${userMinInfo.type === 1 ? 'user' : 'organization'} doesn't have a wallet.`);
      }
    }
  };

  const searchTo = async (value: string) => {
    setAvailable(false);
    if (!value || value.length === 0) {
      setShowResults(false);
      setResults([]);
      return;
    }
    if (value === '0') return;
    if (value === '0x') return;
    if (value.substring(0, 2) === '0x') {
      setShowResults(false);
      if (value.length !== 42) {
        setResults([]);
        setAvailable(false);
        return;
      }
      setAvailable(true);
      setResults([]);
      return;
    }
    setShowResults(true);
    setSearching(true);
    const res = await lambdaSearchWallet(value);
    if (!mounted.current) return;
    setSearching(false);
    if (res && Array.isArray(res)) {
      const receivers = res.filter((item) => item.userMinInfo.uid !== activeInfo.id);
      receivers.sort((r0, r1) => {
        if (r0.wallet && !r1.wallat) return -1;
        if (!r0.wallet && r1.wallet) return 1;
        return 0;
      });
      setResults(receivers);
    } else {
      setResults([]);
    }
  };

  const handleCancelText = () => {
    setTo('');
    setResults([]);
    setAvailable(false);
    setShowCancelButton(false);
    setShowResults(false);
    setSendTotal(false);
    setAmount(0);
  };

  const handleChangeTo = (e) => {
    setTo(e.target.value);
    if (e.target.value.length > 0) {
      setShowCancelButton(true);
    }
    searchTo(e.target.value);
  };

  const handleChooseResult = (userWallet: UserWallet) => {
    if (!userWallet.wallet) {
      toast.error(`Selected ${userWallet.userMinInfo.type === 1 ? 'user' : 'organization'} doesn't have a wallet.`);
      return;
    }
    setTo(userWallet);
    setShowCancelButton(true);
    setAvailable(true);
  };

  const handleChooseRecent = (cliInfo) => {
    const defaultInfo = getUserMinInfoByID(cliInfo);
    const userMinInfo = { ...defaultInfo, allIds: [{ id: defaultInfo.uid, type: defaultInfo.type }] } as UserMinInfo;
    setTo({
      userMinInfo,
      wallet: null
    });
    setShowCancelButton(true);
    checkTo(userMinInfo);
  };

  useEffect(() => {
    if (open) {
      if (wallet.recentClients.length > 0) {
        const notLoadedInfos = wallet.recentClients.filter((info) => !userMinInfos.some((miniUser) => miniUser.allIds.map(({ id }) => id).includes(info.id)));
        if (notLoadedInfos.length > 0) {
          setLoading(true);
          lambdaGetUserMinInfos(notLoadedInfos)
            .then((info) => {
              dispatch(updateUserMinInfos(info));
            })
            .catch((err) => {
              console.log(JSON.stringify(err));
              toast.error('Recent client information loading failed!');
            })
            .finally(() => {
              if (mounted.current) setLoading(false);
            });
        }
      }
      setTo('');
      setResults([]);
      setAvailable(false);
      setShowCancelButton(false);
      setShowResults(false);
      setSendTotal(false);
      setAmount(0);
    }
  }, [open]);

  useEffect(() => {
    if (isAvailable) {
      document.getElementById('Amount')?.focus();
    }
  }, [isAvailable]);

  return (
    <BootstrapDialog
      open={open}
      onClose={onClose}
    >
      <BootstrapDialogTitle
        onClose={onClose}
      >
        {layer2 ? 'Send in Layer2' : 'Send in Layer1'}
      </BootstrapDialogTitle>
      <Scrollbar
        options={{ suppressScrollX: true }}
      >
        <Box
          sx={{
            mx: 2,
            width: '480px'
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 22,
              display: 'flex',
              height: 44,
              px: 2,
            }}
          >
            {isAvailable ? (
              <CheckCircle
                sx={{
                  color: (theme) => theme.palette.success.main
                }}
                fontSize="small"
              />
            ) : (
              <CryptoWallet
                color="action"
                fontSize="small"
              />
            )}
            <Box
              sx={{
                flexGrow: 1,
                pl: 1,
              }}
            >
              {typeof to === 'string' && (
                <Input
                  autoFocus
                  fullWidth
                  disableUnderline
                  onChange={handleChangeTo}
                  placeholder="public address(0x) or your partner's name or email"
                  value={to}
                />
              )}
              {typeof to !== 'string' && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <SmallUserInfo userInfo={to.userMinInfo} />
                </Box>
              )}
            </Box>
            {showCancelButton && (
              <IconButton
                onClick={handleCancelText}
                sx={{
                  p: 0
                }}
              >
                <Close
                  color="action"
                  fontSize="medium"
                />
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              height: 450,
              mt: 2,
            }}
          >
            {isAvailable ? (
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  height="100%"
                >
                  <NumberField
                    fullWidth
                    id="Amount"
                    label="Amount *"
                    margin="normal"
                    name="Amount"
                    onBlur={handleChangeAmount}
                    onChange={handleChangeAmount}
                    onFocus={(e) => e.target.select()}
                    value={amount}
                    variant="outlined"
                    error={amountError}
                    helperText={amountError && 'Amount should be larger than gas limit.'}
                  />
                  <CheckLabel
                    checked={isSendTotal}
                    onChange={() => handleToggleSendTotal()}
                    label="Send total balance"
                    {...{
                      sx: {
                        ml: 2
                      }
                    }}
                  />
                  {!layer2 && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      mt={2}
                    >
                      <GasLimit
                        gasLimit={gasLimit}
                        onSetGasLimit={(v) => setGasLimit(v)}
                        gasParams={{
                          type: 'transfer'
                        }}
                        estimateDisabled={!amount}
                        minGas={1}
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  my={2}
                >
                  <Button
                    color="primary"
                    size="medium"
                    sx={{
                      width: '120px'
                    }}
                    type="button"
                    variant="text"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="medium"
                    sx={{
                      ml: 2,
                      width: '120px'
                    }}
                    type="button"
                    onClick={handleDone}
                    disabled={isSubmitting || amountError}
                    variant="contained"
                  >
                    {isSubmitting && (
                      <CircularProgress
                        color="primary"
                        size={20}
                        sx={{ marginRight: '8px' }}
                      />
                    )}
                    Done
                  </Button>
                </Box>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    mx: 2
                  }}
                >
                  <Table
                    sx={{
                      '& th': {
                        py: 1.5,
                        px: 1,
                        fontSize: '16px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        bgcolor: BACKGROUND_DEEP,
                        color: 'white',
                        fontWeight: 'medium'
                      }
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            py: 1
                          }}
                        >
                          {showResults ? 'Search results' : 'Recents'}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {showResults ? (
                      <TableBody>
                        {!isSearching && results.length > 0 && results.map((result) => (
                          <TableRow
                            hover
                            key={shortId()}
                            onClick={() => handleChooseResult(result)}
                          >
                            <TableCell
                              sx={{
                                py: 1
                              }}
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <SmallUserInfo userInfo={result.userMinInfo} />
                                </Box>
                                {!result.wallet && (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: 'red'
                                    }}
                                  >
                                    no wallet
                                  </Typography>
                                )}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                        {!isSearching && results.length === 0 && (
                          <TableRow>
                            <TableCell
                              sx={{
                                py: 1,
                                border: 0
                              }}
                            >
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{
                                  mt: 1,
                                  display: 'flex',
                                  justifyContent: 'center'
                                }}
                              >
                                No Clients found
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        {isSearching && (
                          <TableRow>
                            <TableCell
                              sx={{
                                py: 1,
                                border: 0
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  minWidth: '100%',
                                }}
                              >
                                <CircularProgress
                                  color="primary"
                                  size={20}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        {isLoading && (
                          <TableRow>
                            <TableCell>
                              <CircularProgress
                                sx={{
                                  ml: 2
                                }}
                                color="primary"
                                size={14}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                        {!isLoading && wallet.recentClients?.map((recent) => (
                          <TableRow
                            hover
                            key={shortId()}
                            onClick={() => handleChooseRecent(recent)}
                          >
                            {(typeof to !== 'string' && recent.id === to.userMinInfo.uid) ? (
                              <TableCell>
                                <Grid
                                  container
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <SmallUserInfo userInfo={getUserMinInfoByID(recent)} />
                                  </Box>
                                  {isChecking && (
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                        ml={4}
                                        align="right"
                                      >
                                        Checking...
                                      </Typography>
                                      <CircularProgress
                                        sx={{
                                          ml: 2
                                        }}
                                        color="primary"
                                        size={14}
                                      />
                                    </Box>
                                  )}
                                </Grid>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <SmallUserInfo userInfo={getUserMinInfoByID(recent)} />
                                </Box>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Scrollbar>
    </BootstrapDialog>
  );
};

MERcTransferDlg.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  layer2: PropTypes.bool.isRequired
};

export default MERcTransferDlg;
