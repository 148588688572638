import store from 'src/store';
import { ACCZIOM_CLIENT, ACCZIOM_ORG, roleInfoData } from 'src/globals';
import { getActiveOrgInfo, getActiveRole, getActiveType } from './getActiveOrgInfo';

export const getExculdeHrefsByType = (): string[] => {
  const excludeHrefs = [];
  switch (getActiveType(ACCZIOM_CLIENT, ACCZIOM_ORG)) {
    case ACCZIOM_CLIENT:
      excludeHrefs.push('/communication/internal');
      break;
    default:
      break;
  }
  return excludeHrefs;
};

export const getPossibleHrefs = (): string[] => {
  const hrefs: string[] = [];
  const { activeTeamId } = store.getState().organization;
  const activeRole = getActiveRole();
  const excludeHrefs = getExculdeHrefsByType();
  roleInfoData.forEach((firstItem) => {
    const firstKey = firstItem.key;
    if (firstKey === 'department' || firstKey === 'assignment' || ((firstKey === 'production' || firstKey === 'transaction') && (getActiveType(ACCZIOM_CLIENT, ACCZIOM_ORG) === ACCZIOM_CLIENT || !getActiveOrgInfo()?.businessInfo?.activatedTeams || !!activeTeamId))) {
      firstItem.value.forEach((secondItem) => {
        const secondKey = secondItem.key;
        secondItem.value.forEach((thirdItem) => {
          const thirdKey = thirdItem.key;
          const thirdHref = thirdItem.href;
          if (activeRole?.[firstKey]?.[secondKey]?.[thirdKey]) {
            thirdHref.forEach((href) => {
              if (!hrefs.includes(href) && !excludeHrefs.includes(href)) hrefs.push(href);
            });
          }
        });
      });
    }
  });

  if (getActiveType(ACCZIOM_CLIENT, ACCZIOM_ORG) === ACCZIOM_ORG) hrefs.push('/management/templates');

  // return hrefs;
  return [...hrefs, '/deal/purchase'];
};

export const getPossibleItems = (items: any[], hrefs: string[] = null): any[] => {
  if (hrefs === null) hrefs = getPossibleHrefs();
  const mm = [];

  items.forEach((item) => {
    if (hrefs.filter((href) => item.href.startsWith(href)).length > 0) mm.push(item);
    else if (item.items?.length > 0) {
      const subItems = [];
      item.items.forEach((subItem) => {
        if (hrefs.filter((href) => subItem.href.startsWith(href)).length > 0) subItems.push(subItem);
        else if (subItem.items?.length > 0) {
          const subSubItems = [];
          subItem.items.forEach((subSubItem) => {
            if (hrefs.filter((href) => subSubItem.href.startsWith(href)).length > 0) subSubItems.push(subSubItem);
          });
          if (subSubItems.length > 0) {
            subItems.push({
              ...subItem,
              items: subSubItems
            });
          }
        }
      });
      if (subItems.length > 0) {
        mm.push({
          ...item,
          items: subItems
        });
      }
    }
  });

  return mm;
};
