import { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
// import Login from 'src/pages/authentication/Login';
import { useDispatch, useSelector } from 'src/store';
import { LoadingStatus, setInviteInfo, setRequestedLocation } from 'src/slices/user';
import SpinnerScreen from './SpinnerScreen';
import initialLoad from 'src/worker/init';
import { getActiveOrgInfo } from 'src/utils/getActiveOrgInfo';
import { STATUS_ACTIVATED, STATUS_PENDING } from 'src/globals';

interface AuthGuardProps {
  children: ReactNode;
  isFree?: boolean;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children, isFree } = props;
  const auth = useAuth();
  const { settings } = useSettings();
  const { pathname } = useLocation();
  const { userInfo, domainName, loadingStatus, requestedLocation, inviteInfo } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>(null);
  const activeOrgInfo = getActiveOrgInfo();

  useEffect(() => {
    if (searchParams.get('state') && searchParams.get('state').split('-').length === 2) {
      const inviteParams = JSON.parse(Buffer.from(searchParams.get('state').split('-')[1], 'hex').toString('utf8'));
      if (inviteParams.params) {
        dispatch(setInviteInfo(inviteParams));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (requestedLocation) dispatch(setRequestedLocation(null));
  }, [requestedLocation]);

  useEffect(() => {
    if (userInfo && userInfo.isFirst) return;
    if (inviteInfo) return;
    if (searchParams.get('state') && searchParams.get('state').split('-').length === 2) return;
    if (!auth.isAuthenticated) return;
    if (loadingStatus === LoadingStatus.NONE) {
      let subdomain = domainName;
      if (!subdomain) {
        if (process.env.NODE_ENV === 'production') {
          const domain = window.location.hostname;
          if (domain !== 'go.accziom.com') {
            const idx = domain.indexOf('.go.accziom.com');
            if (idx > 0) subdomain = domain.substring(0, idx);
            else setError('The organization domain not exists!');
          }
        }
      }
      initialLoad(auth.user.id, auth.checkLive, dispatch, auth.logout, subdomain, settings.orgId, settings.teamId);
    }
  }, [auth, userInfo, loadingStatus, inviteInfo, searchParams, settings]);

  if (error) return <Navigate to="/error" />;

  if (!auth.isAuthenticated) {
    if (!isFree) {
      if (process.env.NODE_ENV === 'production') {
        window.location.href = 'https://go.accziom.com';
        return <SpinnerScreen />;
      }
      return <Navigate to="/" />;
    }
    return <>{children}</>;
  }

  if (searchParams.get('state') && searchParams.get('state').split('-').length === 2) {
    const inviteParams = JSON.parse(Buffer.from(searchParams.get('state').split('-')[1], 'hex').toString('utf8'));
    if (inviteParams.params) return <Navigate to="/acceptinvite" />;
  }

  if (userInfo && !userInfo.isFirst && pathname === '/fillup') {
    return <Navigate to="/home" />;
  }

  if (!inviteInfo && pathname === '/acceptinvite') {
    return <Navigate to="/home" />;
  }

  if (inviteInfo) {
    if (pathname !== '/acceptinvite') {
      return <Navigate to="/acceptinvite" />;
    }
    return <>{children}</>;
  }

  if (userInfo && userInfo.isFirst) {
    if (pathname !== '/fillup') {
      return <Navigate to="/fillup" />;
    }
  } else if (loadingStatus !== LoadingStatus.COMPLETED) {
    return <SpinnerScreen />;
  }

  if (requestedLocation) {
    if (pathname !== requestedLocation) return <Navigate to={requestedLocation} />;
  }

  if (activeOrgInfo && activeOrgInfo.status === STATUS_PENDING) {
    if (pathname === '/payorgfee' || pathname.startsWith('/deal/purchase/detail/')) {
      return <>{children}</>;
    }
    return <Navigate to="/payorgfee" />;
  }

  if (activeOrgInfo && activeOrgInfo.status === STATUS_ACTIVATED && pathname === '/payorgfee') {
    return <Navigate to="/home" />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  isFree: PropTypes.bool
};

export default AuthGuard;
