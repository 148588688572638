import {
  Box,
  CircularProgress,
  TextField
} from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import useMounted from 'src/hooks/useMounted';
import { BACKGROUND_DEEP } from 'src/globals';
import { useDispatch, useSelector } from 'src/store';
import { setQuery } from 'src/slices/user';

const SearchInput: FC<{
  placeholder?: string;
  onSearch: (query: string) => void;
  hasButton: boolean;
  isSearching?: boolean;
  disabled?: boolean;
}> = ({
  placeholder,
  onSearch,
  hasButton,
  isSearching,
  disabled
}) => {
  const mounted = useMounted();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isFocused, setFocused] = useState<boolean>(false);

  const { query } = useSelector((state) => state.user);

  useEffect(() => {
    if (mounted.current) dispatch(setQuery(''));
  }, [mounted, pathname]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: 'relative',
        pl: 3
      }}
    >
      <Box
        sx={{
          position: 'relative',
          transition: '.3s',
          transitionDelay: '.2s',
          width: isFocused ? '100%' : '60%'
        }}
      >
        <TextField
          fullWidth
          onFocus={() => setFocused(true)}
          onBlur={() => {
            setFocused(false);
          }}
          InputProps={{
            sx: {
              fontSize: 15,
              bgcolor: 'background.paper',
              ...(hasButton && { pr: 4 })
            }
          }}
          onChange={(e) => {
            dispatch(setQuery(e.target.value));
            if (!hasButton) onSearch(e.target.value);
          }}
          onKeyDown={async (e) => {
            if ((e.code === 'Enter' || e.code === 'NumpadEnter')) {
              onSearch(query);
            }
          }}
          placeholder={disabled ? '' : placeholder}
          value={query}
          size="small"
          variant="outlined"
          disabled={isSearching || disabled}
        />
        {!disabled && hasButton && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: 1000,
              right: 0,
              top: 0,
              width: 43,
              height: 39,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                width: 37,
                height: 32,
                bgcolor: isSearching ? 'transparent' : 'primary.main',
                borderRadius: 1,
                color: 'background.paper',
                '&:hover': {
                  bgcolor: BACKGROUND_DEEP
                },
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: '.3s',
                opacity: isSearching || isFocused ? 1 : 0.3
              }}
              onClick={() => {
                if (!isSearching) onSearch(query);
              }}
            >
              {isSearching ? (
                <CircularProgress
                  size={16}
                />
              ) : (
                <BiSearch
                  style={{
                    width: 18,
                    height: 18
                  }}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  hasButton: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SearchInput;
