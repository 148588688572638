import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import type { Thread, Message, Contact } from 'src/types/chat';
// modified by Makarov --2021/11/01
import { ACCZIOM_NONE, PAGE_SIZE } from 'src/globals';
import { Tag } from 'src/../../Common/Model/tag';

interface ChatState {
  isNewChatOpen: boolean;
  isInviteOpen: boolean;
  msgEditingItem: any;
  msgQuotingItem: any;
  convs: Thread[];
  activeThreadId?: string;
  lastMsgIds: Record<string, Record<string, number>>;
  tags: Tag[];
  tagsLoaded: boolean;
  selectedMode: string;
}

const initialState: ChatState = {
  isNewChatOpen: false,
  isInviteOpen: false,
  msgEditingItem: null,
  msgQuotingItem: null,
  convs: [],
  activeThreadId: null,
  lastMsgIds: {},
  tags: [],
  tagsLoaded: false,
  selectedMode: 'external'
};

const cmpConvFn = (a: Thread, b: Thread): number => {
  if (a.messages.length === 0) return 1;
  if (b.messages.length === 0) return -1;
  const at: number = a.lastMessageTime;
  const bt: number = b.lastMessageTime;

  return bt - at;
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    cleanAll(state: ChatState): void {
      state.convs.splice(0, state.convs.length);
      state.tags.splice(0, state.tags.length);
      state.isNewChatOpen = false;
      state.isInviteOpen = false;
      state.msgEditingItem = null;
      state.msgQuotingItem = null;
      state.activeThreadId = null;
      state.lastMsgIds = {};
      state.tagsLoaded = false;
      state.selectedMode = 'external';
    },
    threadAdded(state: ChatState, action: PayloadAction<Thread>): void {
      const thread = action.payload;
      let i = 0;
      const c = state.convs.find((item) => item.sid === thread.sid);
      if (c) return;
      for (i = 0; i < state.convs.length; i++) {
        if (state.convs[i].lastMessageTime < thread.lastMessageTime) break;
      }
      // state.convs.unshift(thread);
      state.convs.splice(i, 0, thread);
    },
    threadDeleted(state: ChatState, action: PayloadAction<string>): void {
      const sid = action.payload;
      const c = state.convs.find((item) => item.sid === sid);
      if (!c) return;
      state.convs.splice(state.convs.indexOf(c), 1);
    },
    threadJoined(state: ChatState, action: PayloadAction<string>): void {
      const sid = action.payload;
      const conv = state.convs.find((item) => item.sid === sid);
      if (conv) conv.isJoined = true;
    },
    // created by Makarov --2021/11/01
    minimizeMessages(state: ChatState, action: PayloadAction<any>): void {
      const body = action.payload;
      const conv = state.convs.find((item) => item.sid === body.sid);
      if (conv !== null && conv !== undefined) conv.messages.splice(0, conv.messages.length - PAGE_SIZE);
    },
    setMessages(state: ChatState, action: PayloadAction<Message[]>): void {
      const messages = action.payload;
      if (messages.length < 1) return;
      const conv = state.convs.find((item) => item.sid === messages[0].convId);
      if (!conv) return;
      if (conv.messages.length > 0 && conv.messages[conv.messages.length - 1].id < messages[0].id) {
        conv.messages.push(...messages);
      } else {
        conv.messages.unshift(...messages);
      }
      if (conv.messages[conv.messages.length - 1].id === messages[messages.length - 1].id) {
        conv.lastMessage = messages[messages.length - 1].body;
        conv.lastMessageTime = messages[messages.length - 1].createdAt;
      }
    },
    sortThread(state: ChatState): void {
      state.convs.sort(cmpConvFn);
    },
    setMsgEditingItem(state: ChatState, action: PayloadAction<any>): void {
      state.msgEditingItem = action.payload;
    },
    setMsgQuotingItem(state: ChatState, action: PayloadAction<any>): void {
      state.msgQuotingItem = action.payload;
    },
    setMsgUnreadCount(state: ChatState, action: PayloadAction<any>): void {
      const body = action.payload;
      const conv = state.convs.find((item) => item.sid === body.sid);
      if (conv) {
        conv.unreadCount = body.count;
      }
    },
    updateUserOnlineStatus(state: ChatState, action: PayloadAction<any>): void {
      const body = action.payload;
      state.convs.forEach((conv) => {
        conv.participants.forEach((p) => {
          if (p.uid === body.uid) p.isOnline = body.isOnline;
        });
      });
    },
    setNewChatDlgStatus(state: ChatState, action: PayloadAction<boolean>): void {
      state.isNewChatOpen = action.payload;
    },
    setInviteDlgStatus(state: ChatState, action: PayloadAction<boolean>): void {
      state.isInviteOpen = action.payload;
    },
    msgAdded(state: ChatState, action: PayloadAction<Message>): void {
      const msg = action.payload;
      const conv = state.convs.find((item) => item.sid === msg.convId);
      conv.messages.push(msg);
      conv.lastMessage = msg.body;
      conv.lastMessageTime = msg.createdAt;
      state.convs.sort(cmpConvFn);
    },
    msgSent(state: ChatState, action: PayloadAction<Message>): void {
      const msg = action.payload;
      const conv = state.convs.find((item) => item.sid === msg.convId);
      for (let i = conv.messages.length - 1; i >= 0; i--) {
        const item = conv.messages[i];
        if (item.id < 0 && msg.body === item.body) {
          item.sent = true;
          item.id = msg.id;
          break;
        }
        if (item.id < 0 && msg.attrib !== null && msg.attrib !== undefined && item.attrib !== null && item.attrib !== undefined) {
          if (msg.attrib.size === item.attrib.size && msg.attrib.uri === item.attrib.uri) {
            item.sent = true;
            item.id = msg.id;
          }
        }
      }
    },
    addRecipientsToThread(state: ChatState, action: PayloadAction<any>): void {
      const data = action.payload;
      const conv = state.convs.find((item) => item.sid === data.sid);
      if (conv) conv.participants.push(...data.recipients);
    },
    setMessageImage(state: ChatState, action: PayloadAction<any>): void {
      const { msg } = action.payload;
      const conv = state.convs.find((item) => item.sid === msg.convId);
      if (conv) {
        const m = conv.messages.find((item) => item.id === msg.id);
        m.body = action.payload.data;
      }
    },
    setMessageVideoThumbnail(state: ChatState, action: PayloadAction<any>): void {
      const { msg } = action.payload;
      const conv = state.convs.find((item) => item.sid === msg.convId);
      if (conv) {
        const m = conv.messages.find((item) => item.id === msg.id);
        m.body = action.payload.data;
      }
    },
    msgUpdated(state: ChatState, action: PayloadAction<Message>): void {
      const msg = action.payload;
      const conv = state.convs.find((item) => item.sid === msg.convId);
      if (conv) {
        const m = conv.messages.find((item) => item.id === msg.id);
        m.body = msg.body;
        m.updated = true;
      }
    },
    markThreadAsSeen(state: ChatState, action: PayloadAction<string>): void {
      const threadId = action.payload;
      const conv = state.convs.find((item) => item.sid === threadId);
      if (conv) {
        conv.unreadCount = 0;
      }
    },
    removeMessage(state: ChatState, action: PayloadAction<any>): void {
      const body = action.payload;
      const conv = state.convs.find((item) => item.sid === body.sid);
      if (conv) {
        for (let i = 0; i < conv.messages.length; i++) {
          if (conv.messages[i].id === body.msgId) {
            conv.messages.splice(i, 1);
            break;
          }
        }
      }
    },
    updateLastReadMsgIndex(state: ChatState, action: PayloadAction<any>): void {
      const body = action.payload;
      const conv = state.convs.find((item) => item.sid === body.sid);
      if (conv) {
        conv.lastReadMsgIndex = body.count;
      }
    },
    updateLastMsgIndex(state: ChatState, action: PayloadAction<any>): void {
      const body = action.payload;
      const conv = state.convs.find((item) => item.sid === body.sid);
      if (conv) {
        conv.lastMsgIndex = body.count;
      }
    },
    setTypingStarted(state: ChatState, action: PayloadAction<any>): void {
      const body = action.payload;
      const conv = state.convs.find((item) => item.sid === body.sid);
      if (conv) {
        const participant = conv.participants.find((item) => item.uid === body.uid);
        if (participant) conv.typer = participant;
        else conv.typer = { uid: '', oid: '', type: ACCZIOM_NONE };
        conv.isTyping = true;
      }
    },
    setTypingEnded(state: ChatState, action: PayloadAction<string>): void {
      const sid = action.payload;
      const conv = state.convs.find((item) => item.sid === sid);
      if (conv) {
        conv.typer = { uid: '', oid: '', type: ACCZIOM_NONE };
        conv.isTyping = false;
      }
    },
    setActiveThread(state: ChatState, action: PayloadAction<string>): void {
      state.activeThreadId = action.payload;
    },
    removeParticipants(state: ChatState, action: PayloadAction<any>): void {
      const body = action.payload;
      const conv = state.convs.find((item) => item.sid === body.sid);
      if (conv) {
        conv.participants = conv.participants.filter((participant) => !(body.uids.includes(participant.uid)));
      }
    },
    updateLastReadMsgIds(state: ChatState, action: PayloadAction<Record<string, Record<string, number>>>): void {
      const body = action.payload;
      Object.keys(body).forEach((sid) => {
        if (!(sid in state.lastMsgIds)) state.lastMsgIds[sid] = {};
        Object.keys(body[sid]).forEach((uid) => {
          state.lastMsgIds[sid][uid] = body[sid][uid];
        });
      });
    },
    setTags(state: ChatState, action: PayloadAction<Tag[]>): void {
      state.tags = action.payload;
    },
    setTagsLoadState(state: ChatState, action: PayloadAction<boolean>): void {
      state.tagsLoaded = action.payload;
    },
    setSelectedMode(state: ChatState, action: PayloadAction<string>): void {
      state.selectedMode = action.payload;
    },
    processBeforeChangeMode(state: ChatState): void {
      state.isNewChatOpen = false;
      state.isInviteOpen = false;
      state.msgEditingItem = null;
      state.msgQuotingItem = null;
      state.activeThreadId = null;
    }
  }
});

export const { reducer } = slice;

export const threadJoined = (sid: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.threadJoined(sid));
};
export const threadAdded = (thread: Thread): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.threadAdded(thread));
};
export const threadDeleted = (sid: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.threadDeleted(sid));
};
export const msgAdded = (msg: Message): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.msgAdded(msg));
};

export const msgSent = (msg: Message): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.msgSent(msg));
};

export const msgUpdated = (msg: Message): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.msgUpdated(msg));
};

// created by Makarov --2021/11/01
export const minimizeMessages = (threadId: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.minimizeMessages({ sid: threadId }));
};

export const setMessages = (messages: Message[]): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setMessages(messages));
};

export const setMsgUnreadCount = (threadId: string, num: number): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setMsgUnreadCount({ sid: threadId, count: num }));
};

export const updateLastMsgIndex = (threadId: string, num: number): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateLastMsgIndex({ sid: threadId, count: num }));
};
export const updateLastReadMsgIndex = (threadId: string, num: number): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateLastReadMsgIndex({ sid: threadId, count: num }));
};
export const markThreadAsSeen = (threadId: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.markThreadAsSeen(threadId));
};

export const setActiveThread = (sid: string) => (dispatch): void => {
  dispatch(slice.actions.setActiveThread(sid));
};

export const setTypingStarted = (_sid: string, _uid: string) => (dispatch): void => {
  dispatch(slice.actions.setTypingStarted({ sid: _sid, uid: _uid }));
};

export const setTypingEnded = (sid: string) => (dispatch): void => {
  dispatch(slice.actions.setTypingEnded(sid));
};

export const updateUserOnlineStatus = (_uid: string, _isOnline: boolean) => (dispatch): void => {
  dispatch(slice.actions.updateUserOnlineStatus({ uid: _uid, isOnline: _isOnline }));
};

export const addRecipientsToThread = (_sid: string, _recipients: Contact[]): AppThunk => (dispatch): void => {
  dispatch(slice.actions.addRecipientsToThread({
    sid: _sid,
    recipients: _recipients
  }));
};

export const removeParticipants = (_sid: string, _uids: string[]): AppThunk => (dispatch): void => {
  dispatch(slice.actions.removeParticipants({ sid: _sid, uids: _uids }));
};

export const cleanUpChat = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.cleanAll());
};

export const openNewChatDlg = (show: boolean): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setNewChatDlgStatus(show));
};

export const openInviteDlg = (show: boolean): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setInviteDlgStatus(show));
};

export const setMessageImage = (_msg: Message, _data: string): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setMessageImage({ msg: _msg, data: _data }));
};

export const setMessageVideoThumbnail = (_msg: Message, _data: string): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setMessageVideoThumbnail({ msg: _msg, data: _data }));
};

export const removeMessage = (_sid: string, _msgId: number): AppThunk => (dispatch): void => {
  dispatch(slice.actions.removeMessage({ sid: _sid, msgId: _msgId }));
};

export const setEditingMsg = (_sid: string, _msgId: number): AppThunk => (dispatch): void => {
  if (_sid === '') {
    dispatch(slice.actions.setMsgEditingItem(null));
  } else {
    dispatch(slice.actions.setMsgEditingItem({ sid: _sid, msgId: _msgId }));
  }
};

export const setQuotingMsg = (_sid: string, _msgId: number): AppThunk => (dispatch): void => {
  if (_sid === '') {
    dispatch(slice.actions.setMsgQuotingItem(null));
  } else {
    dispatch(slice.actions.setMsgQuotingItem({ sid: _sid, msgId: _msgId }));
    setTimeout(() => {
      document.getElementById('messageInput').focus();
    }, 100);
  }
};

export const updateLastReadMsgIds = (dat: Record<string, Record<string, number>>): AppThunk => (dispatch): void => {
  dispatch(slice.actions.updateLastReadMsgIds(dat));
};

export const setTags = (tags: Tag[]): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setTags(tags));
};

export const setTagsLoadState = (state: boolean): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setTagsLoadState(state));
};

export const setSelectedMode = (mode: string): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setSelectedMode(mode));
};

export const processBeforeChangeMode = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.processBeforeChangeMode());
};

export default slice;
