import { FC, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Checkbox,
  Radio,
  Select,
  Typography
} from '@material-ui/core';
import { Microservice } from 'src/../../Common/Model/microservice';
import { styled } from '@mui/material/styles';
import {
  null2empty,
  periodLengthList,
  interestTypeModel,
  COMPOUND_INTEREST_TYPE,
  LENGTH_YEAR,
  UPFRONT_PAYMENT,
  COMPLETION_PAYMENT,
  TWOTIME_PAYMENT,
  PERIOD_PAYMENT,
  DELIVERY_PAYMENT,
  INTEREST,
  GST,
  LENGTH_DAY,
  paymentPointDelivery,
  cryptoCurrencies,
  fiatCurrencies,
  currencyTypeToUnit,
  PAY_DIRECT_DEBIT,
  PAY_CRYPTOCURRENCY,
  PAY_STRIPE,
  PAY_CREDIT_CARD,
  MICROSERVICE,
  MICROSERVICE_BUNDLE
} from 'src/globals';
import CurrencyItem from 'src/components/display/CurrencyItem';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PopupTooltip from 'src/components/dialog/PopupTooltip';
import CheckIcon from '@mui/icons-material/Check';
import { displayUnit, getFormatedPrice } from 'src/utils/priceUtils';
import NumberField from 'src/components/interact/NumberField';
import { useSelector } from 'src/store';
import useMounted from 'src/hooks/useMounted';
import CheckLabel from 'src/components/CheckLabel';
import ModificationInfo, { isValueError } from './ModificationInfo';

export const InlineTypo = styled(Typography)(() => ({
  display: 'inline',
  lineHeight: '50px'
}));

export const PTypo: FC<{ type?: string; text?: string }> = ({ type, text }) => {
  switch (type) {
    case 'item':
      return (
        <Typography
          variant="subtitle2"
          color="textSecondary"
          sx={{
            mb: 0.5
          }}
        >
          {null2empty(text)}
        </Typography>
      );
    case 'desc':
      return (
        <Typography
          color="textPrimary"
          variant="body1"
          sx={{
            mt: 0.5
          }}
        >
          {null2empty(text)}
        </Typography>
      );
    case 'up0':
      return (
        <Typography
          variant="subtitle1"
          color="textPrimary"
          sx={{
            fontWeight: 'bold',
            mt: 0.5
          }}
        >
          {null2empty(text)}
        </Typography>
      );
    case 'up1':
      return (
        <Typography
          variant="h6"
          color="textPrimary"
          sx={{
            fontWeight: 'bold',
            mt: 0.5
          }}
        >
          {null2empty(text)}
        </Typography>
      );
    default:
      return <></>;
  }
  return <></>;
};

PTypo.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string
};

export const PText: FC<{
  value?: any;
  setValue?: any;
  props?: any;
  min?: number;
  max?: number;
  fullWidth?: boolean;
}> = ({ value, setValue, props, min, max, fullWidth }) => (
  <NumberField
    fullWidth={!!fullWidth}
    onChange={(event): void => {
      let nval = event.target.value;
      if (nval === '') {
        setValue?.(nval);
        return;
      }
      if (nval === '') nval = `${min}`;
      if (!Number.isNaN(Number(nval))) {
        const nv = parseInt(nval, 10);
        if (min !== null && min !== undefined && nv < min) nval = `${min}`;
        if (max !== null && max !== undefined && nv > max) nval = `${max}`;
        setValue?.(nval);
      }
    }}
    value={value === null || value === undefined ? '' : null2empty(`${value}`)}
    variant="outlined"
    size="small"
    InputProps={{
      ...props,
      sx: {
        '& input': {
          textAlign: 'right'
        }
      }
    }}
    sx={{
      my: '9px',
      ...(fullWidth ? { width: '40%' } : { width: '70px' }),
      '& .MuiOutlinedInput-input': {
        px: 1,
        py: 0.5
      }
    }}
    error={value === ''}
  />
);

PText.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func,
  props: PropTypes.any,
  min: PropTypes.number,
  max: PropTypes.number,
  fullWidth: PropTypes.bool
};

export const PSelect: FC<{
  value?: string;
  setValue?: any;
  items?: any[],
  fullWidth?: boolean
}> = ({ value, setValue, items, fullWidth }) => (
  <FormControl
    fullWidth={!!fullWidth}
    size="small"
    sx={{
      my: 1.1
    }}
  >
    <Select
      sx={{
        '& .MuiSelect-select': {
          px: 1,
          py: 0.5
        }
      }}
      value={null2empty(value)}
      onChange={(event): void => setValue?.(event.target.value)}
    >
      {
        items?.map((item) => (
          <MenuItem
            value={`${item.value}`}
            key={`${item.value}`}
          >
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {item.label}
            </Typography>
          </MenuItem>
        ))
      }
    </Select>
  </FormControl>
);

PSelect.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  items: PropTypes.array,
  fullWidth: PropTypes.bool
};

export const PInput: FC<{ desc?: string; children: any; tip?: string; }> = ({ desc, children, tip }) => (
  <Box
    sx={{
      mt: 1,
      width: '100%',
      flexDirection: 'column'
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <PTypo
        text={desc}
        type="item"
      />
      {tip && (
        <Box
          sx={{
            ml: 0.5,
            mb: 0.5
          }}
        >
          <PopupTooltip>
            {tip}
          </PopupTooltip>
        </Box>
      )}
    </Box>
    {children}
  </Box>
);

PInput.propTypes = {
  desc: PropTypes.string,
  children: PropTypes.node,
  tip: PropTypes.string
};

export const PChildCont: FC<{ isInput?: boolean, mbDoubled?: boolean; children: any }> = ({ isInput, children, mbDoubled }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      mt: isInput ? 0 : 1,
      pl: 3,
      mb: mbDoubled ? 0 : 1
    }}
  >
    {children}
  </Box>
);

PChildCont.propTypes = {
  isInput: PropTypes.bool,
  mbDoubled: PropTypes.bool,
  children: PropTypes.node
};

export const PItemCont: FC<{ desc?: any; type?: string; value?: boolean; half?: boolean; setValue?: any; children?: any, disabled?: boolean }> = ({ desc, type, value, half, setValue, children, disabled }) => {
  if (type === 'check') {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <FormControlLabel
            label={desc}
            control={(
              <Checkbox
                checked={value}
                indeterminate={half}
                style={{
                  color: 'green'
                }}
                disabled={disabled}
                onChange={(e) => setValue?.(e.target.checked)}
              />
            )}
          />
          {/* <CheckLabel
            checked={value}
            onChange={(e) => setValue?.(e.target.checked)}
            label={desc}
            indeterminate={half}
          /> */}
        </Box>
        {(value || half) && (
          <Box
            sx={{
              width: '100%'
            }}
          >
            {children}
          </Box>
        )}
      </Box>
    );
  }
  if (type === 'radio') {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start'
        }}
      >
        <Radio
          checked={value}
          onChange={(e) => setValue?.(e.target.checked)}
        />
        <Box>
          {children}
        </Box>
      </Box>
    );
  }
  return <></>;
};

PItemCont.propTypes = {
  desc: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.bool,
  half: PropTypes.bool,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node
};

export const PTwoItem: FC<{ item0: any; item1: any }> = ({ item0, item1 }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center'
    }}
  >
    <Box
      sx={{
        width: '50%',
        pr: 1
      }}
    >
      {item0}
    </Box>
    <Box
      sx={{
        width: '50%',
        pl: 1
      }}
    >
      {item1}
    </Box>
  </Box>
);

PTwoItem.propTypes = {
  item0: PropTypes.any,
  item1: PropTypes.any
};

export const CurrencyItemWithRate: FC<{ currency: string; onClick: any; selected: boolean; }> = ({ currency, onClick, selected }) => {
  const { curExRate } = useSelector((state) => state.organization);
  const unit = currencyTypeToUnit(currency);
  return (
    <Button
      onClick={onClick}
      sx={{
        '&:hover': {
          bgcolor: '#e3f2fd'
        },
        px: 2,
        width: 400
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        width="100%"
      >
        <CheckIcon
          sx={{
            visibility: selected ? 'visible' : 'hidden',
            mr: 2
          }}
        />
        <Box
          sx={{
            '& p': {
              fontSize: 18
            }
          }}
        >
          <CurrencyItem
            type={currency}
          />
        </Box>
        {displayUnit[unit] !== displayUnit.USD && (
          <Typography
            variant="subtitle2"
            color="GrayText"
            ml={1}
          >
            {`≈ ${getFormatedPrice(1 / curExRate[unit], currency, true)} ${displayUnit.USD}`}
          </Typography>
        )}
      </Box>
    </Button>
  );
};

CurrencyItemWithRate.propTypes = {
  currency: PropTypes.string,
  onClick: PropTypes.any,
  selected: PropTypes.bool
};

export const initialValues = (idx) => {
  switch (idx) {
    case UPFRONT_PAYMENT:
      return {
        type: UPFRONT_PAYMENT,
        initialOffset: '1', // -
        initialAmount: '100',
        finalPeriod: '1',
        finalOffset: '0'
      };
    case COMPLETION_PAYMENT:
      return {
        type: COMPLETION_PAYMENT,
        finalOffset: '1', // -
        initialAmount: '0',
        initialOffset: '0',
        finalPeriod: '1'
      };
    case TWOTIME_PAYMENT:
      return {
        type: TWOTIME_PAYMENT,
        initialAmount: '50', // -
        initialOffset: '1', // -
        finalOffset: '1', // -
        finalPeriod: '1'
      };
    case PERIOD_PAYMENT:
      return {
        type: PERIOD_PAYMENT,
        initialAmount: '50', // -
        initialOffset: '1', // -
        finalPeriod: '3', // -
        finalOffset: '1' // -
      };
    case DELIVERY_PAYMENT:
      return {
        type: DELIVERY_PAYMENT,
        finalOffset: '0', // -
        initialAmount: '0',
        initialOffset: '0', // - for commencement(0) or completion(1)
        finalPeriod: '1'
      };
    case INTEREST:
      return {
        type: `${COMPOUND_INTEREST_TYPE}`,
        rateType: `${LENGTH_YEAR}`,
        rate: '10'
      };
    case GST:
      return {
        rate: '10'
      };
    default:
      return {};
  }
};

export const isEmpty = (service: Microservice) => (
  service.paymentOptions.payment.filter((v) => v !== null).length === 0
  || service.paymentOptions.platforms.length === 0
  || service.paymentOptions.payment.filter((v) => v !== null).some((v) => v.initialOffset === '' || v.initialAmount === '' || v.finalPeriod === '' || v.finalOffset === '')
  || isValueError(service.paymentOptions.modifications)
);

const PaymentPad: FC<{ service: Microservice; setService: any; }> = ({ service, setService }) => {
  const mounted = useMounted();
  const [isAutoDebit, setAutoDebit] = useState<boolean>(false);
  const spo = Object.keys(service.paymentOptions).length === 0 ? [] : service.paymentOptions.payment;
  const bspo = Object.keys(service.paymentOptions).length === 0 ? [] : service.paymentOptions.payment.map((v) => v !== null);

  useEffect(() => {
    if (mounted.current && service.paymentOptions.platforms.length > 0) {
      setAutoDebit(service.paymentOptions.platforms[0].paymentMethod === PAY_DIRECT_DEBIT);
    }
  }, [mounted]);

  const newService = (vals) => {
    const payment = service.paymentOptions.payment.map((v, i) => ((i in vals) ? vals[i] : v));
    return {
      ...service,
      paymentOptions: {
        ...service.paymentOptions,
        payment
      },
      props: {
        ...service.props,
        showSchedule: !!payment[PERIOD_PAYMENT]
      }
    };
  };

  const updateService = (idx, k, v) => {
    setService({
      ...service,
      paymentOptions: {
        ...service.paymentOptions,
        payment: service.paymentOptions.payment.map((itm, i) => (i === idx ? { ...itm, [k]: v } : itm))
      }
    });
  };

  const currencySelected = (cur) => (
    !!service.paymentOptions.platforms
    && service.paymentOptions.platforms.some(
      ({ paymentCurrency }) => paymentCurrency === currencyTypeToUnit(cur)
    )
  );

  const updateCurrency = (cur) => {
    let newCurrencies = [];
    if (currencySelected(cur)) newCurrencies = service.paymentOptions.platforms.filter(({ paymentCurrency }) => paymentCurrency !== currencyTypeToUnit(cur));
    else {
      newCurrencies = [...service.paymentOptions.platforms, {
        paymentCurrency: currencyTypeToUnit(cur),
        paymentTool: cryptoCurrencies.includes(cur) ? PAY_CRYPTOCURRENCY : PAY_STRIPE,
        paymentMethod: isAutoDebit ? PAY_DIRECT_DEBIT : PAY_CREDIT_CARD
      }];
    }
    setService({
      ...service,
      paymentOptions: {
        ...service.paymentOptions,
        platforms: newCurrencies
      }
    });
  };

  const updateAutoDebit = (val) => {
    setAutoDebit(val);
    setService({
      ...service,
      paymentOptions: {
        ...service.paymentOptions,
        platforms: service.paymentOptions.platforms.map((currency) => ({
          ...currency,
          paymentMethod: val ? PAY_DIRECT_DEBIT : PAY_CREDIT_CARD
        }))
      }
    });
  };

  return (
    <Box
      sx={{
        px: 6,
        pb: 2,
        bgcolor: 'background.paper'
      }}
    >
      <Box
        sx={{
          pt: 2,
          pl: 4
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          mb={0.5}
        >
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{
              ml: 2,
              mr: 0.5
            }}
          >
            Payment Options
          </Typography>
          <PopupTooltip>
            You can provide multiple payment options to your customer for this service, and the customer will select one of them when requesting this service.
          </PopupTooltip>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <PChildCont>
          <PItemCont
            type="check"
            desc={(
              <PTypo
                type="up1"
                text={service.bundleType === MICROSERVICE ? 'Requires payment independent of service delivery' : 'Requires payment independent of delivery of each service in the bundle'}
              />
            )}
            value={bspo[UPFRONT_PAYMENT] && bspo[COMPLETION_PAYMENT] && bspo[TWOTIME_PAYMENT] && bspo[PERIOD_PAYMENT]}
            half={!(bspo[UPFRONT_PAYMENT] && bspo[COMPLETION_PAYMENT] && bspo[TWOTIME_PAYMENT] && bspo[PERIOD_PAYMENT]) && (bspo[UPFRONT_PAYMENT] || bspo[COMPLETION_PAYMENT] || bspo[TWOTIME_PAYMENT] || bspo[PERIOD_PAYMENT])}
            setValue={(val) => {
              setService(newService(val ? {
                [UPFRONT_PAYMENT]: initialValues(UPFRONT_PAYMENT),
                [COMPLETION_PAYMENT]: initialValues(COMPLETION_PAYMENT),
                [TWOTIME_PAYMENT]: initialValues(TWOTIME_PAYMENT),
                [PERIOD_PAYMENT]: initialValues(PERIOD_PAYMENT)
              } : {
                [UPFRONT_PAYMENT]: null,
                [COMPLETION_PAYMENT]: null,
                [TWOTIME_PAYMENT]: null,
                [PERIOD_PAYMENT]: null
              }));
            }}
          >
            <PChildCont mbDoubled>
              <PItemCont
                type="check"
                desc={(
                  <PTypo
                    type="up0"
                    text="Require one-time payment"
                  />
                )}
                value={bspo[UPFRONT_PAYMENT] && bspo[COMPLETION_PAYMENT]}
                half={!(bspo[UPFRONT_PAYMENT] && bspo[COMPLETION_PAYMENT]) && (bspo[UPFRONT_PAYMENT] || bspo[COMPLETION_PAYMENT])}
                setValue={(val) => {
                  setService(newService(val ? {
                    [UPFRONT_PAYMENT]: initialValues(UPFRONT_PAYMENT),
                    [COMPLETION_PAYMENT]: initialValues(COMPLETION_PAYMENT)
                  } : {
                    [UPFRONT_PAYMENT]: null,
                    [COMPLETION_PAYMENT]: null
                  }));
                }}
              >
                <PChildCont>
                  <PItemCont
                    type="check"
                    desc={(
                      <PTypo
                        type="desc"
                        text="Require upfront payment"
                      />
                    )}
                    value={bspo[UPFRONT_PAYMENT]}
                    setValue={(val) => setService(newService({ [UPFRONT_PAYMENT]: val ? initialValues(UPFRONT_PAYMENT) : null }))}
                  >
                    <PChildCont isInput>
                      <Box>
                        <InlineTypo>An invoice will be issued within&nbsp;&nbsp;</InlineTypo>
                        <PText
                          value={spo[UPFRONT_PAYMENT] && spo[UPFRONT_PAYMENT].initialOffset}
                          setValue={(val) => updateService(UPFRONT_PAYMENT, 'initialOffset', val)}
                          min={0}
                        />
                        <InlineTypo>&nbsp;&nbsp;days of signing the contract.</InlineTypo>
                      </Box>
                    </PChildCont>
                  </PItemCont>
                </PChildCont>
                <PChildCont mbDoubled>
                  <PItemCont
                    type="check"
                    desc={(
                      <PTypo
                        type="desc"
                        text="Require payment on completion"
                      />
                    )}
                    value={bspo[COMPLETION_PAYMENT]}
                    setValue={(val) => setService(newService({ [COMPLETION_PAYMENT]: val ? initialValues(COMPLETION_PAYMENT) : null }))}
                  >
                    <PChildCont isInput>
                      <Box>
                        <InlineTypo>An invoice will be issued within&nbsp;&nbsp;</InlineTypo>
                        <PText
                          value={spo[COMPLETION_PAYMENT] && spo[COMPLETION_PAYMENT].finalOffset}
                          setValue={(val) => updateService(COMPLETION_PAYMENT, 'finalOffset', val)}
                          min={0}
                        />
                        <InlineTypo>&nbsp;&nbsp;days of completing all scheduled delivery tasks.</InlineTypo>
                      </Box>
                    </PChildCont>
                  </PItemCont>
                </PChildCont>
              </PItemCont>
              <PItemCont
                type="check"
                desc={(
                  <PTypo
                    type="up0"
                    text="Require two-time payments"
                  />
                )}
                value={bspo[TWOTIME_PAYMENT]}
                setValue={(val) => setService(newService({ [TWOTIME_PAYMENT]: val ? initialValues(TWOTIME_PAYMENT) : null }))}
              >
                <PChildCont>
                  <Box>
                    <InlineTypo>An invoice for the initial deposit equal to&nbsp;&nbsp;</InlineTypo>
                    <PText
                      props={{ endAdornment: '%' }}
                      value={spo[TWOTIME_PAYMENT] && spo[TWOTIME_PAYMENT].initialAmount}
                      setValue={(val) => updateService(TWOTIME_PAYMENT, 'initialAmount', val)}
                      min={1}
                      max={99}
                    />
                    <InlineTypo>&nbsp;&nbsp; of the total payment will be issued within&nbsp;&nbsp;</InlineTypo>
                    <PText
                      value={spo[TWOTIME_PAYMENT] && spo[TWOTIME_PAYMENT].initialOffset}
                      setValue={(val) => updateService(TWOTIME_PAYMENT, 'initialOffset', val)}
                      min={0}
                    />
                    <InlineTypo>&nbsp;&nbsp;days of signing the contract.</InlineTypo>
                  </Box>
                </PChildCont>
                <PChildCont>
                  <Box>
                    <InlineTypo>An invoice for the balance equal to&nbsp;&nbsp;</InlineTypo>
                    <PText
                      props={{ endAdornment: '%', readOnly: true }}
                      value={spo[TWOTIME_PAYMENT] && spo[TWOTIME_PAYMENT].initialAmount !== '' ? `${100 - parseInt(spo[TWOTIME_PAYMENT].initialAmount, 10)}` : ''}
                    />
                    <InlineTypo>&nbsp;&nbsp;of the total payment will be issued within&nbsp;&nbsp;</InlineTypo>
                    <PText
                      value={spo[TWOTIME_PAYMENT] && spo[TWOTIME_PAYMENT].finalOffset}
                      setValue={(val) => updateService(TWOTIME_PAYMENT, 'finalOffset', val)}
                      min={0}
                    />
                    <InlineTypo>&nbsp;&nbsp;days of completing all scheduled delivery tasks.</InlineTypo>
                  </Box>
                </PChildCont>
              </PItemCont>
              <PItemCont
                type="check"
                desc={(
                  <PTypo
                    type="up0"
                    text="Require periodical and equal instalments"
                  />
                )}
                value={bspo[PERIOD_PAYMENT]}
                setValue={(val) => setService(newService({ [PERIOD_PAYMENT]: val ? initialValues(PERIOD_PAYMENT) : null }))}
              >
                <PChildCont>
                  <Box>
                    <InlineTypo>An invoice for the initial deposit equal to&nbsp;&nbsp;</InlineTypo>
                    <PText
                      props={{ endAdornment: '%' }}
                      value={spo[PERIOD_PAYMENT] && spo[PERIOD_PAYMENT].initialAmount}
                      setValue={(val) => updateService(PERIOD_PAYMENT, 'initialAmount', val)}
                      min={0}
                      max={99}
                    />
                    <InlineTypo>&nbsp;&nbsp;of the total payment will be issued within&nbsp;&nbsp;</InlineTypo>
                    <PText
                      value={spo[PERIOD_PAYMENT] && spo[PERIOD_PAYMENT].initialOffset}
                      setValue={(val) => updateService(PERIOD_PAYMENT, 'initialOffset', val)}
                      min={0}
                    />
                    <InlineTypo>&nbsp;&nbsp;days of signing the contract.</InlineTypo>
                  </Box>
                </PChildCont>
                <PChildCont mbDoubled>
                  <Box>
                    <InlineTypo>Invoices for the balance equal to&nbsp;&nbsp;</InlineTypo>
                    <PText
                      props={{ endAdornment: '%' }}
                      value={spo[PERIOD_PAYMENT] && `${100 - Number(spo[PERIOD_PAYMENT].initialAmount)}`}
                      setValue={(val) => updateService(PERIOD_PAYMENT, 'initialAmount', `${100 - Number(val)}`)}
                      min={1}
                      max={100}
                    />
                    <InlineTypo>&nbsp;&nbsp;of the total payment will be issued periodically, once a &nbsp;&nbsp;</InlineTypo>
                    <PSelect
                      items={periodLengthList.filter((itm) => itm.type >= LENGTH_DAY).map((itm) => ({
                        value: `${itm.type}`,
                        label: itm.text.toLowerCase()
                      }))}
                      value={spo[PERIOD_PAYMENT] && `${spo[PERIOD_PAYMENT].finalPeriod}`}
                      setValue={(val) => updateService(PERIOD_PAYMENT, 'finalPeriod', val)}
                    />
                    <InlineTypo>&nbsp;.</InlineTypo>
                  </Box>
                </PChildCont>
                <PChildCont mbDoubled>
                  <Box>
                    <InlineTypo>Also, the first invoice for the balance will be issued within&nbsp;&nbsp;</InlineTypo>
                    <PText
                      value={spo[PERIOD_PAYMENT] && spo[PERIOD_PAYMENT].finalOffset}
                      setValue={(val) => updateService(PERIOD_PAYMENT, 'finalOffset', val)}
                      min={0}
                      // max={(spo[PERIOD_PAYMENT] && spo[PERIOD_PAYMENT].finalPeriod) ? parseInt(spo[PERIOD_PAYMENT].finalPeriod, 10) - 1 : null}
                    />
                    <InlineTypo>&nbsp;&nbsp;days of the effective date of the contract.</InlineTypo>
                  </Box>
                </PChildCont>
              </PItemCont>
            </PChildCont>
          </PItemCont>
          <PItemCont
            type="check"
            desc={(
              <PTypo
                type="up1"
                text={service.bundleType === MICROSERVICE ? 'Requires payment related to service delivery' : 'Requires payment related to each delivery of each service in the bundle'}
              />
            )}
            value={bspo[DELIVERY_PAYMENT]}
            setValue={(val) => setService(newService({ [DELIVERY_PAYMENT]: val ? initialValues(DELIVERY_PAYMENT) : null }))}
          >
            <PChildCont isInput>
              <Box
                display="flex"
                alignItems="center"
                sx={{ height: '50px' }}
              >
                <Radio
                  checked={spo[DELIVERY_PAYMENT] && Number(spo[DELIVERY_PAYMENT].finalOffset) > 0}
                  onChange={(event) => {
                    if (event.target.checked) updateService(DELIVERY_PAYMENT, 'finalOffset', '1');
                  }}
                  sx={{
                    p: 0,
                    m: 0,
                    mr: 2
                  }}
                />
                <Typography>
                  {service.bundleType === MICROSERVICE && 'Invoice after delivery commencement or completion'}
                  {service.bundleType === MICROSERVICE_BUNDLE && 'Invoice after every delivery commencement or completion'}
                </Typography>
              </Box>
              {spo[DELIVERY_PAYMENT] && Number(spo[DELIVERY_PAYMENT].finalOffset) > 0 && (
                <Box sx={{ ml: 5 }}>
                  <InlineTypo>Invoice will be issued within&nbsp;&nbsp;</InlineTypo>
                  <PText
                    value={spo[DELIVERY_PAYMENT] && `${Math.abs(spo[DELIVERY_PAYMENT].finalOffset)}`}
                    setValue={(val) => updateService(DELIVERY_PAYMENT, 'finalOffset', `${val}`)}
                    min={1}
                  />
                  <InlineTypo>&nbsp;&nbsp;days of every&nbsp;&nbsp;</InlineTypo>
                  <PSelect
                    items={paymentPointDelivery}
                    value={spo[DELIVERY_PAYMENT] && `${spo[DELIVERY_PAYMENT].initialOffset}`}
                    setValue={(val) => updateService(DELIVERY_PAYMENT, 'initialOffset', val)}
                  />
                  <InlineTypo>&nbsp;, even for recurring delivery.</InlineTypo>
                </Box>
              )}
            </PChildCont>
            <PChildCont>
              <Box
                display="flex"
                alignItems="center"
                sx={{ height: '50px' }}
              >
                <Radio
                  checked={spo[DELIVERY_PAYMENT] && Number(spo[DELIVERY_PAYMENT].finalOffset) <= 0}
                  onChange={(event) => {
                    if (event.target.checked) updateService(DELIVERY_PAYMENT, 'finalOffset', '0');
                  }}
                  sx={{
                    p: 0,
                    m: 0,
                    mr: 2
                  }}
                />
                <Typography>
                  {service.bundleType === MICROSERVICE && 'Invoice before delivery commencement or completion'}
                  {service.bundleType === MICROSERVICE_BUNDLE && 'Invoice before every delivery commencement or completion'}
                </Typography>
              </Box>
              {spo[DELIVERY_PAYMENT] && Number(spo[DELIVERY_PAYMENT].finalOffset) <= 0 && (
                <Box sx={{ ml: 5 }}>
                  <InlineTypo>Invoice will be issued&nbsp;&nbsp;</InlineTypo>
                  <PText
                    value={spo[DELIVERY_PAYMENT] && `${Math.abs(spo[DELIVERY_PAYMENT].finalOffset)}`}
                    setValue={(val) => updateService(DELIVERY_PAYMENT, 'finalOffset', `${Number(val) * (-1)}`)}
                    min={0}
                  />
                  <InlineTypo>&nbsp;&nbsp;days prior to every&nbsp;&nbsp;</InlineTypo>
                  <PSelect
                    items={paymentPointDelivery}
                    value={spo[DELIVERY_PAYMENT] && `${spo[DELIVERY_PAYMENT].initialOffset}`}
                    setValue={(val) => updateService(DELIVERY_PAYMENT, 'initialOffset', val)}
                  />
                  <InlineTypo>&nbsp;, even for recurring delivery.</InlineTypo>
                </Box>
              )}
            </PChildCont>
          </PItemCont>
        </PChildCont>
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{
            mt: 2,
            ml: 2
          }}
        >
          Pay on Invoice
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <PChildCont>
          <Box
            display="flex"
            alignItems="center"
          >
            <Checkbox
              checked
              disabled
              sx={{
                p: 0,
                m: 0,
                mr: 2
              }}
            />
            <Box>
              <InlineTypo>All invoices are payable within&nbsp;&nbsp;</InlineTypo>
              <PText
                value={service.paymentOptions.invoiceOffset}
                setValue={(val) => setService({
                  ...service,
                  paymentOptions: {
                    ...service.paymentOptions,
                    invoiceOffset: val
                  }
                })}
                min={0}
              />
              <InlineTypo>&nbsp;&nbsp;days of the date of invoice.</InlineTypo>
            </Box>
          </Box>
        </PChildCont>
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{
            mt: 2,
            ml: 2
          }}
        >
          Interest Option
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <PChildCont>
          <PItemCont
            type="check"
            // value={service.paymentOptions.interest !== null}
            value={false}
            setValue={(val) => setService({
              ...service,
              paymentOptions: {
                ...service.paymentOptions,
                interest: val ? initialValues(INTEREST) : null
              }
            })}
            disabled
            desc={(
              <PTypo
                type="up0"
                text="Require interest for late payments"
              />
            )}
          >
            <PChildCont isInput>
              <PTwoItem
                item0={(
                  <PInput
                    desc="Interest type"
                    tip="Simple Interest: Calculated annually on the amount you deposit or owe. Compound Interest: Interest earned is added to the principal, forming a new base on which the next round of interest is calculated. This can accrue daily, monthly, or quarterly."
                  >
                    <PSelect
                      fullWidth
                      items={interestTypeModel.map(({ value, label }) => ({ value, label: label.toUpperCase() }))}
                      value={service.paymentOptions.interest && `${service.paymentOptions.interest.type}`}
                      setValue={(val) => setService({
                        ...service,
                        paymentOptions: {
                          ...service.paymentOptions,
                          interest: {
                            ...service.paymentOptions.interest,
                            type: val
                          }
                        }
                      })}
                    />
                  </PInput>
                )}
                item1={(
                  <PInput desc="Interest rate type">
                    <PSelect
                      fullWidth
                      items={periodLengthList.filter((itm) => itm.type >= LENGTH_DAY).map((itm) => ({
                        value: `${itm.type}`,
                        label: `${itm.adverb.toUpperCase()} INTEREST RATE`
                      }))}
                      value={service.paymentOptions.interest && `${service.paymentOptions.interest.rateType}`}
                      setValue={(val) => setService({
                        ...service,
                        paymentOptions: {
                          ...service.paymentOptions,
                          interest: {
                            ...service.paymentOptions.interest,
                            rateType: val
                          }
                        }
                      })}
                    />
                  </PInput>
                )}
              />
              <PInput desc="Interest rate">
                <PText
                  fullWidth
                  props={{ endAdornment: '%' }}
                  value={service.paymentOptions.interest && service.paymentOptions.interest.rate}
                  setValue={(val) => setService({
                    ...service,
                    paymentOptions: {
                      ...service.paymentOptions,
                      interest: {
                        ...service.paymentOptions.interest,
                        rate: val
                      }
                    }
                  })}
                  min={1}
                />
              </PInput>
            </PChildCont>
          </PItemCont>
        </PChildCont>
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{
            mt: 2,
            ml: 2
          }}
        >
          GST Option
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <PChildCont>
          <PItemCont
            type="check"
            value={service.paymentOptions.gst !== null}
            setValue={(val) => setService({
              ...service,
              paymentOptions: {
                ...service.paymentOptions,
                gst: val ? initialValues(GST) : null
              }
            })}
            desc={(
              <PTypo
                type="up0"
                text="Require GST Inclusion"
              />
            )}
          >
            <PChildCont isInput>
              <PInput desc="GST rate">
                <PText
                  fullWidth
                  props={{ endAdornment: '%' }}
                  value={service.paymentOptions.gst && service.paymentOptions.gst.rate}
                  setValue={(val) => setService({
                    ...service,
                    paymentOptions: {
                      ...service.paymentOptions,
                      gst: {
                        rate: val
                      }
                    }
                  })}
                  min={1}
                />
              </PInput>
            </PChildCont>
          </PItemCont>
        </PChildCont>
        {service.bundleType === MICROSERVICE && (
          <>
            <Typography
              variant="h6"
              color="textSecondary"
              sx={{
                mt: 2,
                ml: 2
              }}
            >
              Modifications
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <PChildCont>
              <ModificationInfo
                values={!service.paymentOptions.modifications ? [] : service.paymentOptions.modifications}
                update={(v) => {
                  setService({
                    ...service,
                    paymentOptions: {
                      ...service.paymentOptions,
                      modifications: v
                    }
                  });
                }}
                currency={service.defaultCurrency}
              />
            </PChildCont>
          </>
        )}
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{
              ml: 2,
              mr: 0.5
            }}
          >
            Payment Platform Settings
          </Typography>
          <PopupTooltip>
            Customers are allowed to pay for this service in currencies selected below.
          </PopupTooltip>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <PChildCont>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              pb: 1
            }}
          >
            <CurrencyBitcoinIcon
              sx={{
                width: 24,
                height: 24
              }}
            />
            <Typography
              variant="subtitle1"
              fontWeight="bold"
            >
              Crypto
            </Typography>
          </Box>
          <Box
            sx={{
              pl: 3,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {cryptoCurrencies.map((cur) => (
              <CurrencyItemWithRate
                key={cur}
                currency={cur}
                onClick={() => updateCurrency(cur)}
                selected={currencySelected(cur)}
              />
            ))}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              py: 1
            }}
          >
            <AttachMoneyIcon
              sx={{
                width: 24,
                height: 24
              }}
            />
            <Typography
              variant="subtitle1"
              fontWeight="bold"
            >
              Fiat
            </Typography>
          </Box>
          <Box
            sx={{
              pl: 3,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {fiatCurrencies.map((cur) => (
              <CurrencyItemWithRate
                key={cur}
                currency={cur}
                onClick={() => updateCurrency(cur)}
                selected={currencySelected(cur)}
              />
            ))}
          </Box>
          <Box>
            <PItemCont
              type="check"
              value={isAutoDebit}
              setValue={updateAutoDebit}
              desc={(
                <PTypo
                  type="desc"
                  text="Clients should open auto-debit to request this service, and all fees will be paid automatically."
                />
              )}
            />
          </Box>
        </PChildCont>
        <Divider sx={{ mb: 2 }} />
        <PChildCont>
          <Box
            display="flex"
            alignItems="center"
          >
            <CheckLabel
              checked={service.props.showPrice}
              onChange={(e) => {
                setService({
                  ...service,
                  props: {
                    ...service.props,
                    showPrice: e.target.checked
                  }
                });
              }}
              label="Show Service Total Price"
            />
          </Box>
          {!!service.paymentOptions.payment[PERIOD_PAYMENT] && (
            <Box
              display="flex"
              alignItems="center"
              sx={{
                mt: 1
              }}
            >
              <CheckLabel
                checked={service.props.showSchedule}
                onChange={(e) => {
                  setService({
                    ...service,
                    props: {
                      ...service.props,
                      showSchedule: e.target.checked
                    }
                  });
                }}
                label="Show Sequential Payment Frequency"
              />
            </Box>
          )}
        </PChildCont>
      </Box>
    </Box>
  );
};

PaymentPad.propTypes = {
  service: PropTypes.any,
  setService: PropTypes.func
};

export default PaymentPad;
