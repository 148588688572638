import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import PropTypes from 'prop-types';

const AlertLoginDialog: FC<{
  open: boolean;
  handleClose: any;
  handleSign: any;
}> = ({
  open,
  handleClose,
  handleSign
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
  >
    <DialogTitle>
      You aren&apos;t signed in.
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        You should sign in first to request contract. Your data will be saved, would you mind to continue to sign in?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        onClick={handleSign}
        autoFocus
        variant="contained"
      >
        Sign in
      </Button>
    </DialogActions>
  </Dialog>
);

AlertLoginDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSign: PropTypes.func,
};

export default AlertLoginDialog;
