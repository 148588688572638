import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tab,
  Tabs,
  Typography,
  TextField
} from '@material-ui/core';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { ChangeEvent, forwardRef, useCallback, useEffect, useState, useRef } from 'react';
import toast from 'react-hot-toast';
import useMounted from 'src/hooks/useMounted';
import { useDispatch, useSelector } from 'src/store';
import SearchIcon from 'src/icons/Search';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { lambdaSearchUserWithFriend, lambdaSearchUserByField, lambdaSearchOrganizationByField, lambdaSendRawFeedback } from 'src/aws/lambdaDispatch';
import type { User } from 'src/../../Common/Model/user';
import type { Organization, OrgMemberForOrg, OrgTeam } from 'src/../../Common/Model/organization';
import type { Contact } from 'src/../../Common/Model/contact';
import type { UserMinInfo } from 'src/../../Common/Model/common';
import {
  applyFilters as filterContacts,
  getSimpleContactAddress,
  getEmailAddress,
  getAnyPhoneNumber
} from 'src/utils/contactUtils';
import {
  ACCZIOM_USER,
  ACCZIOM_ORG,
  convertTextToHtml,
  getInviteEmailBody,
  getInviteEmailSubject,
  INVITE_ITEM_HEIGHT,
  isValidEmail,
  linkifyText,
  LEAVE_WINDOW_TITLE,
  LEAVE_WINDOW_CONTENT,
  TAB_LABEL_ACCZIOM_USERS,
  TAB_LABEL_MY_CONTACTS,
  TAB_LABEL_ORG_CLIENTS,
  TAB_LABEL_ORG_MEMBERS,
  TAB_VALUE_ACCZIOM_USERS,
  TAB_VALUE_MY_CONTACTS,
  TAB_VALUE_ORG_CLIENTS,
  TAB_VALUE_ORG_MEMBERS,
  ACCZIOM_CLIENT,
  ACCZIOM_MEMBER,
  NO_TITLE_FIRSTMSG,
  BOTH_TITLE_FIRSTMSG,
  STATUS_ACTIVATED,
  ACCZIOM_TEAM,
  ONLY_TITLE,
  ONLY_FIRSTMSG,
  ACCZIOM_CLIENT_ORG,
  TAB_VALUE_PARTNERS,
  TAB_LABEL_PARTNERS,
  TAB_LABEL_ORG_SUPPLIERS,
  TAB_VALUE_ORG_SUPPLIERS,
  CHAT_MEMBER_MODE,
  ACCZIOM_SUPPLIER,
  tenary,
  ACCZIOM_NONE
} from 'src/globals';
import Scrollbar from 'src/components/layout/Scrollbar';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import memoize from 'memoize-one';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import UserAddIcon from 'src/icons/UserAdd';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Close from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import DblButtonMessage from 'src/components/dialog/DblButtonMessage';
import { getCountry } from 'src/utils/countries';
import { addContactToUsers } from 'src/slices/contact';
import getInitials, { getInitialBackground } from 'src/utils/getInitials';
import getUserDisplayName, { getContactDisplayName } from 'src/utils/getUserDisplayName';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AttributionIcon from '@mui/icons-material/Attribution';
import { UserAvatar } from 'src/components/SmallUserInfo';
import { orgToMiniUser, userToMiniUser } from 'src/utils/getUserMinInfo';
import { getActiveMinInfo, getActiveOrgInfo, getUserMinInfoByID } from 'src/utils/getActiveOrgInfo';

interface OrgMemberInfo extends UserMinInfo {
  member: OrgMemberForOrg;
  teams: OrgTeam[];
}

interface InviteDialogProps {
  open: boolean;
  isTitled: number;
  invitingTitle?: string;
  setInvitingTitle?: (title: string) => void;
  firstMsg?: string;
  setFirstMsg?: (msg: string) => void;
  showTabs: string[];
  showUser?: number;
  showRange?: number;
  maxInvitees?: number;
  invitees: UserMinInfo[];
  hideUserIds: string[];
  loadingCompleted?: boolean;
  addInvitee: (candidate: UserMinInfo) => void;
  removeInvitee: (candidateId: string) => void;
  toSubmit: () => void;
  toClose: () => void;
}

interface RenderRowProps {
  index: number;
  data: any;
}

const tabs = [
  { label: TAB_LABEL_ACCZIOM_USERS, value: TAB_VALUE_ACCZIOM_USERS },
  { label: TAB_LABEL_MY_CONTACTS, value: TAB_VALUE_MY_CONTACTS },
  { label: TAB_LABEL_PARTNERS, value: TAB_VALUE_PARTNERS },
  { label: TAB_LABEL_ORG_CLIENTS, value: TAB_VALUE_ORG_CLIENTS },
  { label: TAB_LABEL_ORG_SUPPLIERS, value: TAB_VALUE_ORG_SUPPLIERS },
  { label: TAB_LABEL_ORG_MEMBERS, value: TAB_VALUE_ORG_MEMBERS }
];

export const getRealInviteeInfo = (invitee: UserMinInfo): any => {
  if (invitee.allIds?.length > 0) return invitee.allIds.at(invitee.allIds.length - 1);
  return { id: '', type: ACCZIOM_NONE };
};

export const getRealInviteeId = (invitee: UserMinInfo): string => (getRealInviteeInfo(invitee).id);
export const getRealInviteeType = (invitee: UserMinInfo): number => (getRealInviteeInfo(invitee).type);

const getStrInviteeType = (type: number): string => {
  let retType = '';
  if (type === ACCZIOM_USER) retType = 'INDIVIDUAL';
  if (type === ACCZIOM_ORG) retType = 'BUSINESS';
  if (type === ACCZIOM_TEAM) retType = 'TEAM';
  if (type === ACCZIOM_MEMBER) retType = 'MEMBER';
  if (type === ACCZIOM_CLIENT || type === ACCZIOM_CLIENT_ORG) retType = 'CLIENT';
  if (type === ACCZIOM_SUPPLIER) retType = 'SUPPLIER';
  return retType;
};

const ChatScrollbar = ({ forwardedRef, ...other }) => {
  const { style, children } = other;
  const refSetter = useCallback(
    (scrollbarsRef) => {
      if (scrollbarsRef) {
        forwardedRef(scrollbarsRef.view);
      } else {
        forwardedRef(null);
      }
    },
    [forwardedRef]
  );

  return (
    <Scrollbar
      ref={refSetter}
      style={{ ...style, overflow: 'hidden' }}
      options={{ suppressScrollX: true }}
    >
      {children}
    </Scrollbar>
  );
};

ChatScrollbar.propTypes = {
  forwardedRef: PropTypes.any
};

const ChatScrollbarVirtualList = forwardRef((props, ref) => (
  <ChatScrollbar
    forwardedRef={ref}
    {...props}
  />
));

function RenderInviteeRow(props: RenderRowProps): JSX.Element {
  const { data, index } = props;
  const { handleRemoveRecipient, recipients } = data;
  const invitee = recipients[index];

  const handleRemoveClick = () => {
    handleRemoveRecipient(invitee.uid);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <ListItem
        button
        key={invitee.uid}
        sx={{
          py: 1,
          px: 2
        }}
      >
        <ListItemAvatar>
          <UserAvatar
            userInfo={invitee}
            size={55}
          />
        </ListItemAvatar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 'calc(100% - 106px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            px: 1
          }}
        >
          <ListItemText
            primary={getUserDisplayName(invitee)}
            primaryTypographyProps={{
              color: 'textPrimary',
              noWrap: true,
              variant: 'body1'
            }}
          />
          <ListItemText
            secondary={getStrInviteeType(getRealInviteeType(invitee))}
            secondaryTypographyProps={{
              color: 'textSecondary',
              noWrap: true,
              variant: 'body2',
              marginLeft: 1
            }}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          onClick={handleRemoveClick}
          sx={{
            width: 50,
            height: 50,
          }}
        >
          <HighlightOffIcon fontSize="medium" />
        </IconButton>
      </ListItem>
      <Divider />
    </Box>
  );
}

function RenderSearchUserRow(props: RenderRowProps): JSX.Element {
  const { data, index } = props;
  const { handleAddRecipient, filteredSearchUsers } = data;
  const user = filteredSearchUsers[index] as UserMinInfo;

  return (
    <ListItem
      button
      key={user.uid}
      onClick={(): void => handleAddRecipient({ ...user, allIds: [{ id: user.uid, type: user.type }] })}
      sx={{
        px: 2
      }}
    >
      <ListItemAvatar>
        <UserAvatar
          userInfo={user}
          size={40}
        />
      </ListItemAvatar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <ListItemText
          primary={getUserDisplayName(user)}
          primaryTypographyProps={{
            color: 'textPrimary',
            noWrap: true,
            variant: 'body1'
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <ListItemText
            secondary={user.type === ACCZIOM_USER ? 'INDIVIDUAL' : 'BUSINESS'}
            secondaryTypographyProps={{
              color: 'textSecondary',
              noWrap: true,
              variant: 'body2',
              marginLeft: 1,
              width: 100
            }}
          />
          <LocationOnIcon
            sx={{
              mt: 0.5,
              ml: 1,
              mr: 0.5
            }}
            fontSize="small"
          />
          {
            user.city?.length + user.country?.length > 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                {
                  user.city?.length > 0 && (
                    <ListItemText
                      secondary={user.country?.length > 0 ? `${user.city},` : user.city}
                      secondaryTypographyProps={{
                        color: 'textSecondary',
                        noWrap: true,
                        variant: 'body2'
                      }}
                    />
                  )
                }
                {
                  user.country?.length > 0 && (
                    <ListItemText
                      secondary={getCountry(user.country)?.text}
                      secondaryTypographyProps={{
                        color: 'textSecondary',
                        noWrap: true,
                        variant: 'body2'
                      }}
                      sx={{
                        ml: user.city?.length > 0 ? 0.5 : 0
                      }}
                    />
                  )
                }
              </Box>
            ) : (
              <ListItemText
                secondary="[No locations]"
                secondaryTypographyProps={{
                  color: 'textSecondary',
                  noWrap: true,
                  variant: 'body2'
                }}
              />
            )
          }
        </Box>
      </Box>
    </ListItem>
  );
}

function RenderSearchContactRow(props: RenderRowProps): JSX.Element {
  const { data, index } = props;
  const { handleAddRecipient, handleSendInvitation, filteredSearchContacts, userName } = data;
  const contact = filteredSearchContacts[index];
  const displayName = getContactDisplayName(contact);
  const address = getSimpleContactAddress(contact);
  const phone = getAnyPhoneNumber(contact);
  const email = getEmailAddress(contact);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contactUsers, setContactUsers] = useState<UserMinInfo[]>([]);
  const [editInvitation, setEditInvitation] = useState<boolean>(false);
  const [msgBody, setMsgBody] = useState<string>(getInviteEmailBody(displayName, userName));
  const mounted = useMounted();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const { contactToUsers } = useSelector((state) => state.contact);

  const getUsersFromContact = async () => {
    const contactData = contactToUsers.find((contactInfo) => contactInfo.cid === contact.id);
    if (contactData) {
      setContactUsers(contactData.userData as UserMinInfo[]);
      setIsLoading(false);
    } else {
      const contactEmail = getEmailAddress(contact);
      if (contactEmail.length > 0) {
        try {
          const promises = [];
          const userData = [];
          promises.push(lambdaSearchUserByField(contactEmail, 'email')
            .then((users: User[]) => {
              users.forEach((user) => userData.push(userToMiniUser(user)));
            })
            .catch((err) => {
              console.log(JSON.stringify(err));
            }));
          promises.push(lambdaSearchOrganizationByField(contactEmail, 'email')
            .then((organizations: Organization[]) => {
              organizations.forEach((org) => userData.push(orgToMiniUser(org)));
            })
            .catch((err) => {
              console.log(JSON.stringify(err));
            }));
          await Promise.all(promises);
          if (mounted.current) setContactUsers(userData);
          dispatch(addContactToUsers(contact.id, userData));
        } catch (err) {
          if (mounted.current) setContactUsers([]);
          console.log(JSON.stringify(err));
        } finally {
          if (mounted.current) setIsLoading(false);
        }
      } else {
        setContactUsers([]);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getUsersFromContact();
  }, [mounted]);

  const handleClick = (): void => contactUsers.forEach((_user) => handleAddRecipient({ ..._user, allIds: [{ id: _user.uid, type: _user.type }] }));

  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setMsgBody(event.target.value);
  };

  const handleSendClick = (): void => {
    handleSendInvitation(email, getInviteEmailSubject(userName), msgBody);
    setMsgBody(getInviteEmailBody(displayName, userName));
    setEditInvitation(false);
  };

  return (
    <ListItem
      button
      key={contact.id}
      onClick={handleClick}
      disabled={isLoading}
      sx={{
        width: '100%'
      }}
      ref={anchorRef}
    >
      <ListItemAvatar
        sx={{
          ml: 1
        }}
      >
        <Avatar
          src=""
          sx={{
            width: 40,
            height: 40,
            fontSize: 20,
            bgcolor: getInitialBackground(getInitials(getContactDisplayName(contact))),
            color: (theme) => theme.palette.text.primary,
            fontWeight: 'bold'
          }}
        >
          {getInitials(getContactDisplayName(contact))}
        </Avatar>
      </ListItemAvatar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: 'calc(100% - 128px)'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '40%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <ListItemText
              primary={displayName?.length > 20 ? displayName.slice(0, 20) : displayName}
              primaryTypographyProps={{
                color: 'textPrimary',
                noWrap: true,
                variant: 'body1'
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <PhoneIcon
              sx={{
                mt: 0.5,
                ml: 1,
                mr: 1
              }}
              fontSize="small"
            />
            <ListItemText
              secondary={phone?.length > 0 ? phone : '[No phone numbers]'}
              secondaryTypographyProps={{
                color: 'textSecondary',
                noWrap: true,
                variant: 'body2'
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '60%',
            mt: 0.5
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <LocationOnIcon
              sx={{
                mt: 0.5,
                ml: 1,
                mr: 1
              }}
              fontSize="small"
            />
            <ListItemText
              secondary={address?.length > 0 ? address : '[No locations]'}
              secondaryTypographyProps={{
                color: 'textSecondary',
                noWrap: true,
                variant: 'body2'
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <EmailIcon
              sx={{
                mt: 0.5,
                ml: 1,
                mr: 1
              }}
              fontSize="small"
            />
            <ListItemText
              secondary={email?.length > 0 ? email : '[No email addresses]'}
              secondaryTypographyProps={{
                color: 'textSecondary',
                noWrap: true,
                variant: 'body2'
              }}
            />
          </Box>
        </Box>
      </Box>
      {
        (contactUsers.length === 0) && (
          <Box>
            <IconButton
              disabled={!(email?.length > 0)}
              onClick={() => setEditInvitation(true)}
            >
              <SendRoundedIcon fontSize="medium" />
            </IconButton>
          </Box>
        )
      }
      {
        editInvitation && (
          <Popover
            anchorEl={anchorRef.current}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top'
            }}
            open
            PaperProps={{
              sx: {
                minWidth: 350,
                minHeight: 230,
                ml: -10
              }
            }}
            transitionDuration={500}
          >
            <Box
              sx={{
                width: '100%',
                height: 'calc(100% - 45px)',
                p: 1
              }}
            >
              <TextField
                placeholder="Leave a message"
                defaultValue={msgBody}
                fullWidth
                multiline
                rows={9}
                onChange={handleMessageChange}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                mb: 1,
                mr: 1
              }}
            >
              <Button
                color="primary"
                variant="contained"
                sx={{
                  marginLeft: 1
                }}
                onClick={handleSendClick}
              >
                Send
              </Button>
              <Button
                color="primary"
                variant="text"
                onClick={() => setEditInvitation(false)}
              >
                Cancel
              </Button>
            </Box>
          </Popover>
        )
      }
    </ListItem>
  );
}

function RenderSearchMemberRow(props: RenderRowProps): JSX.Element {
  const { data, index } = props;
  const { handleAddRecipient, filteredSearchUsers } = data;
  const { memberRolesInCurOrg } = useSelector((state) => state.organization);
  const activeOrgInfo = getActiveOrgInfo();
  const curMember = filteredSearchUsers[index] as OrgMemberInfo;
  const curMemberType = getRealInviteeType(curMember as UserMinInfo);
  const isSupplier = curMemberType === ACCZIOM_CLIENT_ORG && curMember.member?.uid === activeOrgInfo.organizationId;
  const curRole = curMemberType === ACCZIOM_MEMBER ? memberRolesInCurOrg.find((role) => role.rid === curMember.member?.rid)?.name : tenary(isSupplier, 'SUPPLIER', 'CLIENT');

  return (
    <ListItem
      button
      key={curMember.uid}
      onClick={(): void => {
        handleAddRecipient({
          ...curMember,
          allIds: [{ id: curMember.uid, type: curMember.type }, getRealInviteeInfo(curMember)]
        } as UserMinInfo);
      }}
      sx={{
        px: 2
      }}
    >
      <ListItemAvatar>
        <UserAvatar
          userInfo={curMember as UserMinInfo}
          size={40}
        />
      </ListItemAvatar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: 'calc(100% - 128px)'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <ListItemText
              primary={getUserDisplayName(curMember)}
              primaryTypographyProps={{
                color: 'textPrimary',
                noWrap: true,
                variant: 'body1'
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
              }}
            >
              <AttributionIcon
                sx={{
                  mt: 0.5,
                  ml: 1,
                  mr: 0.5
                }}
                fontSize="small"
              />
              <ListItemText
                secondary={curRole}
                secondaryTypographyProps={{
                  color: 'textSecondary',
                  noWrap: true,
                  variant: 'body2'
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            mt: 0.5
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
              pl: 1
            }}
          >
            <UserAvatar
              userInfo={getActiveMinInfo()}
              size={20}
            />
            <ListItemText
              primary={activeOrgInfo.tradingName}
              primaryTypographyProps={{
                color: 'textPrimary',
                noWrap: true,
                variant: 'body2'
              }}
              sx={{
                ml: 0.5
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <PeopleAltIcon
              sx={{
                ml: 1,
                mr: 0.5
              }}
              fontSize="small"
            />
            {
              curMember.teams?.length > 0 ? (
                <ListItemText
                  secondary={curMember.teams.map((team) => team.name).join(', ')}
                  secondaryTypographyProps={{
                    color: 'textSecondary',
                    noWrap: true,
                    variant: 'body2'
                  }}
                />
              ) : (
                <ListItemText
                  secondary="[No Teams]"
                  secondaryTypographyProps={{
                    color: 'textSecondary',
                    noWrap: true,
                    variant: 'body2'
                  }}
                />
              )
            }
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
}

const createInviteeData = memoize((handleRemoveRecipient, recipients) => ({
  handleRemoveRecipient,
  recipients
}));

const createSearchUserData = memoize((handleAddRecipient, filteredSearchUsers) => ({
  handleAddRecipient,
  filteredSearchUsers
}));

const createSearchContactData = memoize((handleAddRecipient, handleSendInvitation, filteredSearchContacts, userName) => ({
  handleAddRecipient,
  handleSendInvitation,
  filteredSearchContacts,
  userName
}));

const getFilteredSearchMembers = (
  searchMembers: OrgMemberInfo[],
  notAvailableIds: string[]
): OrgMemberInfo[] => (searchMembers.filter((member) => !notAvailableIds.includes(member.uid)));

const getFilteredSearchContacts = (
  searchContacts: Contact[],
  recipients: UserMinInfo[]
): any[] => {
  if (searchContacts === null) return [];
  const recipientEmails = recipients.reduce((acc, recipient) => [
    ...acc,
    recipient.email
  ], []);
  return searchContacts.filter((contact) => !recipientEmails.includes(getEmailAddress(contact)));
};

const getFilteredSearchUsers = (
  searchUsers: User[],
  searchOrganizations: Organization[],
  notAvailableIds: string[]
): UserMinInfo[] => {
  const filtered = [];
  if (searchUsers?.length > 0) filtered.push(...searchUsers.filter((user) => !notAvailableIds.includes(user.uid)).map((user) => userToMiniUser(user)));
  if (searchOrganizations?.length > 0) filtered.push(...searchOrganizations.filter((org) => !notAvailableIds.includes(org.organizationId)).map((org) => orgToMiniUser(org)));
  return filtered;
};

const filterPartners = (partners: UserMinInfo[], searchText: string): UserMinInfo[] => (
  partners.filter((partner) => {
    let matches = true;
    if (searchText) {
      const properties = ['firstName', 'middleName', 'lastName', 'penName', 'email'];
      let containsQuery = false;
      properties.forEach((property) => {
        if (partner[property]?.toLowerCase().includes(searchText.toLowerCase())) {
          containsQuery = true;
        }
      });
      if (!containsQuery) {
        matches = false;
      }
    }
    return matches;
  })
);

const filterMembers = (members: OrgMemberInfo[], searchText: string): OrgMemberInfo[] => (
  members.filter((member) => {
    let matches = true;
    if (searchText) {
      const properties = ['firstName', 'middleName', 'lastName', 'penName', 'email'];
      let containsQuery = false;
      properties.forEach((property) => {
        if (member[property]?.toLowerCase().includes(searchText.toLowerCase())) {
          containsQuery = true;
        }
      });
      if (!containsQuery) {
        member.teams.forEach((team) => {
          if (team.name?.toLowerCase().includes(searchText.toLowerCase())) {
            containsQuery = true;
          }
        });
      }
      if (!containsQuery) {
        matches = false;
      }
    }
    return matches;
  })
);

const InviteDialog: FC<InviteDialogProps> = (dlgProps) => {
  const {
    open,
    isTitled,
    invitingTitle,
    setInvitingTitle,
    firstMsg,
    setFirstMsg,
    showTabs,
    showUser,
    showRange,
    maxInvitees,
    invitees,
    hideUserIds,
    loadingCompleted,
    addInvitee,
    removeInvitee,
    toSubmit,
    toClose,
    ...props
  } = dlgProps;
  const { contacts } = useSelector((state) => state.contact);
  const { userInfo } = useSelector((state) => state.user);
  const userName = userInfo.firstName + (userInfo.lastName.length > 0 ? ` ${userInfo.lastName}` : '');
  const { activeOrgId, membersInCurOrg, memberRolesInCurOrg, teamsInCurOrg } = useSelector((state) => state.organization);
  const { invitations: gInvitations } = useSelector((state) => state.network);
  const mounted = useMounted();
  const navigate = useNavigate();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [availPartners, setAvailPartners] = useState<UserMinInfo[]>([]);
  const [availMembers, setAvailMembers] = useState<OrgMemberInfo[]>([]);
  const [query, setQuery] = useState<string>('');
  const [searchUsers, setSearchUsers] = useState<User[]>([]);
  const [searchOrganizations, setSearchOrganizations] = useState<Organization[]>([]);
  const [searchContacts, setSearchContacts] = useState<Contact[]>(contacts);
  const [searchPartners, setSearchPartners] = useState<UserMinInfo[]>(availPartners);
  const [searchMembers, setSearchMembers] = useState<OrgMemberInfo[]>(availMembers);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>(showTabs[0]);
  const [isMsgboxIsOpen, setIsMsgboxIsOpen] = useState<boolean>(false);
  const [editInvitation, setEditInvitation] = useState<boolean>(false);
  const [msgBody, setMsgBody] = useState<string>(getInviteEmailBody('', userName));

  const getHideIds = (): string[] => {
    const hideIds = [...hideUserIds, ...invitees.map(({ uid }) => uid)];
    return hideIds.filter((id1, index) => index === hideIds.findIndex((id2) => id1 === id2));
  };
  const notAvailableIds = getHideIds();

  const filteredSearchUsers = getFilteredSearchUsers(
    searchUsers,
    searchOrganizations,
    notAvailableIds
  );
  const newInviteShow: boolean = filteredSearchUsers.length < 1 && currentTab === TAB_VALUE_ACCZIOM_USERS;
  const newInviteShowWithEmail: boolean = newInviteShow && isValidEmail(query) && query.toLowerCase() !== userInfo.email;

  const filteredSearchContacts = getFilteredSearchContacts(
    searchContacts,
    invitees
  );
  const newContactShow: boolean = filteredSearchContacts.length < 1 && currentTab === TAB_VALUE_MY_CONTACTS;

  const filteredSearchPartners = searchPartners.filter((partner) => !notAvailableIds.includes(partner.uid));
  const newPartnerShow: boolean = filteredSearchPartners.length < 1 && currentTab === TAB_VALUE_PARTNERS;

  const filteredSearchMembers = getFilteredSearchMembers(
    searchMembers,
    notAvailableIds
  );
  const filteredSearchOrgClients = filteredSearchMembers.filter((member) => [ACCZIOM_CLIENT, ACCZIOM_CLIENT_ORG].includes(getRealInviteeType(member)));
  const filteredSearchOrgSuppliers = filteredSearchMembers.filter((member) => getRealInviteeType(member) === ACCZIOM_SUPPLIER);
  const filteredSearchOrgMembers = filteredSearchMembers.filter((member) => getRealInviteeType(member) === ACCZIOM_MEMBER);
  const newMemberShow: boolean = ((filteredSearchOrgClients.length < 1) && (currentTab === TAB_VALUE_ORG_CLIENTS))
    || ((filteredSearchOrgSuppliers.length < 1) && (currentTab === TAB_VALUE_ORG_SUPPLIERS))
    || ((filteredSearchOrgMembers.length < 1) && (currentTab === TAB_VALUE_ORG_MEMBERS));

  const closeInviteDlg = () => {
    toClose();
  };

  const handleSearchChange = async (value: string): Promise<void> => {
    setQuery(value);
    if (value.length > 0) {
      if (showTabs.includes(TAB_VALUE_MY_CONTACTS)) {
        const contactData: Contact[] = filterContacts(contacts, value);
        setSearchContacts(contactData);
      }
      if (showTabs.includes(TAB_VALUE_PARTNERS)) {
        const partnerData: UserMinInfo[] = filterPartners(availPartners, value);
        setSearchPartners(partnerData);
      }
      if (showTabs.includes(TAB_VALUE_ORG_CLIENTS) || showTabs.includes(TAB_VALUE_ORG_SUPPLIERS) || showTabs.includes(TAB_VALUE_ORG_MEMBERS)) {
        const memberData: OrgMemberInfo[] = filterMembers(availMembers, value);
        setSearchMembers(memberData);
      }
    } else {
      if (showTabs.includes(TAB_VALUE_MY_CONTACTS)) setSearchContacts(contacts);
      if (showTabs.includes(TAB_VALUE_PARTNERS)) setSearchPartners(availPartners);
      if (showTabs.includes(TAB_VALUE_ORG_CLIENTS) || showTabs.includes(TAB_VALUE_ORG_SUPPLIERS) || showTabs.includes(TAB_VALUE_ORG_MEMBERS)) setSearchMembers(availMembers);
    }
    if (showTabs.includes(TAB_VALUE_ACCZIOM_USERS)) {
      setIsSearching(true);
      try {
        const res = await lambdaSearchUserWithFriend(value, showUser);
        if (res && !res.errorMessage) {
          const { users, organizations } = res;
          if (mounted.current) {
            setSearchUsers(users as User[]);
            setSearchOrganizations(organizations as Organization[]);
          }
        }
      } catch (err) {
        toast.error('Searching failed!');
        console.log(JSON.stringify(err));
      } finally {
        if (mounted.current) setIsSearching(false);
      }
    }
  };

  const handleAddRecipient = (recipient: any): void => {
    if (maxInvitees && invitees.length >= maxInvitees) {
      toast.error(`You can only invite up to ${maxInvitees === 1 ? 'one user' : `${maxInvitees} users`}.`);
      return;
    }
    addInvitee(recipient as UserMinInfo);
  };

  const handleRemoveRecipient = (uid: string): void => {
    removeInvitee(uid);
  };

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const onYesClick = (): void => {
    closeInviteDlg();
    if (newContactShow) {
      navigate('/management/network/contact');
    } else if (newMemberShow) {
      navigate(`/management/structure/${currentTab}`);
    } else if (newPartnerShow) {
      navigate('/management/network/findpartner');
    }
  };

  const msgboxCloseHandler = (isOpen: boolean): void => setIsMsgboxIsOpen(isOpen);

  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setMsgBody(event.target.value);
  };

  const handleSendInvitation = async (emailTo: string, subject: string, body: string): Promise<void> => {
    if (isValidEmail(emailTo)) {
      const emailParams = {
        from: 'andrew@nobleaccounting.com.au',
        to: emailTo,
        subject,
        html: linkifyText(convertTextToHtml(body)),
      };
      if (emailParams) {
        try {
          const res = await lambdaSendRawFeedback(emailParams, []);
          if (res && res.messageId) {
            toast.success('Message has been sent successfully.');
          }
        } catch (e) {
          toast.error('Error occured while sending message.');
        }
      }
    } else {
      toast.error('The invitation email cannot be sent because his/her email address is not valid.');
    }
  };

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>): void => {
    setInvitingTitle(event.target.value as string);
  };

  const handleChangeFirstMsg = (event: ChangeEvent<HTMLInputElement>): void => {
    setFirstMsg(event.target.value as string);
  };

  const handleSendClick = (): void => {
    handleSendInvitation(query, getInviteEmailSubject(userName), msgBody);
    setMsgBody(getInviteEmailBody('', userName));
    setEditInvitation(false);
  };

  const getTabLabel = (value: string) => {
    let retLabel = '';
    switch (value) {
      case TAB_VALUE_MY_CONTACTS:
        retLabel = `${TAB_LABEL_MY_CONTACTS} (${filteredSearchContacts.length})`;
        break;
      case TAB_VALUE_PARTNERS:
        retLabel = `${TAB_VALUE_PARTNERS} (${filteredSearchPartners.length})`;
        break;
      case TAB_VALUE_ORG_CLIENTS:
        retLabel = `${TAB_VALUE_ORG_CLIENTS} (${filteredSearchOrgClients.length})`;
        break;
      case TAB_VALUE_ORG_SUPPLIERS:
        retLabel = `${TAB_VALUE_ORG_SUPPLIERS} (${filteredSearchOrgSuppliers.length})`;
        break;
      case TAB_VALUE_ORG_MEMBERS:
        retLabel = `${TAB_VALUE_ORG_MEMBERS} (${filteredSearchOrgMembers.length})`;
        break;
      default:
        retLabel = `${TAB_VALUE_ACCZIOM_USERS} (${filteredSearchUsers.length})`;
        break;
    }
    return retLabel;
  };

  const inviteeKey = (index, data) => data.recipients[index].uid;
  const inviteeData = createInviteeData(handleRemoveRecipient, invitees);

  const searchUserKey = (index, data) => data.filteredSearchUsers[index].uid;
  const searchContactKey = (index, data) => data.filteredSearchContacts[index].id;
  const searchContactData = createSearchContactData(handleAddRecipient, handleSendInvitation, filteredSearchContacts, userName);

  useEffect(() => {
    if (loadingCompleted) {
      if (showTabs.includes(TAB_VALUE_PARTNERS)) {
        const orgPartners = [];
        gInvitations.filter((invitation) => invitation.status === STATUS_ACTIVATED).forEach((invitation) => {
          const miniPartner = getUserMinInfoByID(invitation.invitee.id === activeOrgId ? invitation.inviter : invitation.invitee);
          if (miniPartner) orgPartners.push({ ...miniPartner, allIds: [{ id: miniPartner.uid, type: miniPartner.type }] } as UserMinInfo);
        });
        setAvailPartners(orgPartners);
        setSearchPartners(orgPartners);
      }
      if (showTabs.includes(TAB_VALUE_ORG_CLIENTS) || showTabs.includes(TAB_VALUE_ORG_SUPPLIERS) || showTabs.includes(TAB_VALUE_ORG_MEMBERS)) {
        const orgMembers = [];
        membersInCurOrg.forEach((mi) => {
          let matches = mi.status === STATUS_ACTIVATED && mi.uid !== userInfo.uid;
          if (matches) {
            if (mi.type === ACCZIOM_MEMBER) {
              matches = matches && showTabs.includes(TAB_VALUE_ORG_MEMBERS);
              if (matches && showRange === CHAT_MEMBER_MODE) {
                const role = memberRolesInCurOrg.find((roleItem) => roleItem.rid === mi.rid);
                const editChat = role.transaction.messaging.messaging;
                matches = matches && editChat;
              }
            } else if (mi.type === ACCZIOM_CLIENT) {
              matches = matches && showTabs.includes(TAB_VALUE_ORG_CLIENTS);
            } else if (mi.type === ACCZIOM_CLIENT_ORG) {
              if (mi.oid === activeOrgId) matches = matches && showTabs.includes(TAB_VALUE_ORG_CLIENTS);
              else if (mi.uid === activeOrgId) matches = matches && showTabs.includes(TAB_VALUE_ORG_SUPPLIERS);
              else matches = false;
            }
          }
          if (matches) {
            const miniMember = getUserMinInfoByID({ id: mi.mid, type: mi.type });
            if (miniMember) {
              orgMembers.push({
                ...miniMember,
                allIds: [
                  { id: miniMember.uid, type: miniMember.type },
                  { id: mi.mid, type: mi.type === ACCZIOM_CLIENT_ORG ? tenary(mi.oid === activeOrgId, ACCZIOM_CLIENT_ORG, ACCZIOM_SUPPLIER) : mi.type }
                ],
                member: mi,
                teams: teamsInCurOrg.filter((team) => mi.tids.includes(team.tid))
              } as OrgMemberInfo);
            }
          }
        });
        setAvailMembers(orgMembers);
        setSearchMembers(orgMembers);
      }
    }
  }, [loadingCompleted]);

  useEffect(() => {
    if (mounted.current && open) {
      handleSearchChange('');
      setCurrentTab(showTabs[0]);
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={toClose}
      open={open}
    >
      <Formik
        initialValues={{
          submit: null
        }}
        onSubmit={async (values, {
          setErrors,
          setStatus,
          setSubmitting
        }): Promise<void> => {
          try {
            await toSubmit();
            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
              closeInviteDlg();
            }
          } catch (err) {
            toast.error('Internal Error, Please try again');
            if (mounted.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleSubmit,
          isSubmitting
        }): JSX.Element => (
          <form
            noValidate
            onSubmit={handleSubmit}
            style={{
              padding: 20
            }}
            {...props}
          >
            <Box
              sx={{
                position: 'absolute',
                right: 10,
                top: 10
              }}
            >
              <IconButton
                sx={{
                  width: 45,
                  height: 45,
                }}
                onClick={closeInviteDlg}
                disabled={isSubmitting}
              >
                <Close
                  fontSize="medium"
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                mt: -1,
                mr: -1,
                pt: '45px'
              }}
            />
            {
              (isTitled === ONLY_TITLE || isTitled === BOTH_TITLE_FIRSTMSG) && (
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    borderTopRightRadius: 12,
                    px: 2,
                    py: 2,
                    mt: -4
                  }}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                  >
                    Title :
                  </Typography>
                  <TextField
                    sx={{
                      ml: 5
                    }}
                    autoFocus
                    placeholder="Input a new title..."
                    name="title"
                    type="text"
                    onChange={handleChangeTitle}
                    value={invitingTitle}
                    variant="outlined"
                    style={{ width: '50%' }}
                    size="small"
                  />
                </Box>
              )
            }
            {
              (isTitled === ONLY_FIRSTMSG || isTitled === BOTH_TITLE_FIRSTMSG) && (
                <>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      borderTopRightRadius: 12,
                      px: isTitled === BOTH_TITLE_FIRSTMSG ? 3 : 2,
                      mt: isTitled === BOTH_TITLE_FIRSTMSG ? -1 : -1,
                      py: isTitled === BOTH_TITLE_FIRSTMSG ? 0 : 2
                    }}
                  >
                    <TextField
                      label="First Message"
                      onChange={handleChangeFirstMsg}
                      value={firstMsg}
                      variant="outlined"
                      size="small"
                      multiline
                      rows={5}
                      fullWidth
                    />
                  </Box>
                  <Divider
                    sx={{
                      mx: 2,
                      my: 1,
                      borderStyle: 'dotted',
                      borderWidth: '2px',
                      borderBottom: 'none',
                      borderLeft: 'none',
                      borderRight: 'none'
                    }}
                  />
                </>
              )
            }
            <Box>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  borderTopRightRadius: 12,
                  px: 2,
                  py: isTitled > NO_TITLE_FIRSTMSG ? 0 : 1,
                  mt: isTitled > NO_TITLE_FIRSTMSG ? 0 : -3
                }}
              >
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  Invitees :
                </Typography>
                <TextField
                  sx={{
                    ml: 2
                  }}
                  autoFocus
                  style={{ width: '50%' }}
                  size="small"
                  InputProps={{
                    sx: {
                      fontSize: 15
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  placeholder="From Accziom directory"
                  value={query}
                  onChange={(event) => handleSearchChange(event.target.value)}
                />
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'row-reverse'
                }}
              >
                <Box
                  sx={{
                    width: '30%',
                    mr: 3,
                  }}
                >
                  <div
                    style={{
                      height: '100%'
                    }}
                  >
                    <AutoSizer>
                      {({ height, width }) => (
                        <List
                          height={height}
                          itemCount={invitees.length}
                          itemSize={1}
                          itemKey={inviteeKey}
                          itemData={inviteeData}
                          outerElementType={ChatScrollbarVirtualList}
                          width={width}
                        >
                          {RenderInviteeRow}
                        </List>
                      )}
                    </AutoSizer>
                  </div>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    ml: 2,
                    mr: 2
                  }}
                  variant="middle"
                />
                <Box sx={{ width: '60%' }}>
                  <Tabs
                    indicatorColor="primary"
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    textColor="primary"
                    value={currentTab}
                    variant="scrollable"
                  >
                    {showTabs.includes(currentTab) && tabs.filter((tab) => showTabs.includes(tab.value)).map((tab) => (
                      <Tab
                        key={tab.value}
                        label={getTabLabel(tab.value)}
                        value={tab.value}
                      />
                    ))}
                  </Tabs>
                  <Divider />
                  <Box
                    sx={{
                      mx: 2,
                      my: 1,
                      height: (newInviteShowWithEmail || newContactShow || newPartnerShow || newMemberShow) ? 300 - INVITE_ITEM_HEIGHT : 300
                    }}
                  >
                    {
                      isSearching && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              width: '100%'
                            }}
                          >
                            <Loader
                              width="40"
                              height="40"
                              type="Oval"
                              color="rgb(0, 119, 60)"
                            />
                          </Box>
                        </Box>
                      )
                    }
                    {
                      !isSearching && (newInviteShow || newContactShow || newPartnerShow || newMemberShow) && (
                        <Box
                          sx={{
                            p: 2,
                            textAlign: 'center'
                          }}
                        >
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="subtitle2"
                          >
                            {`No ${currentTab}s have been found!`}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                          >
                            {(newMemberShow || newPartnerShow) ? `You can invite a new ${currentTab} into your organization via Accziom website.` : 'You can invite a new friend via email address or phone number.'}
                          </Typography>
                        </Box>
                      )
                    }
                    {
                      !isSearching && !(newInviteShow || newContactShow || newPartnerShow || newMemberShow) && (
                        <div
                          style={{
                            height: '100%'
                          }}
                        >
                          {
                            currentTab === TAB_VALUE_ACCZIOM_USERS && (
                              <AutoSizer>
                                {({ height, width }) => (
                                  <List
                                    height={height}
                                    itemCount={filteredSearchUsers.length}
                                    itemSize={1}
                                    itemKey={searchUserKey}
                                    itemData={createSearchUserData(handleAddRecipient, filteredSearchUsers)}
                                    outerElementType={ChatScrollbarVirtualList}
                                    width={width}
                                  >
                                    {RenderSearchUserRow}
                                  </List>
                                )}
                              </AutoSizer>
                            )
                          }
                          {
                            currentTab === TAB_VALUE_MY_CONTACTS && (
                              <AutoSizer>
                                {({ height, width }) => (
                                  <List
                                    height={height}
                                    itemCount={filteredSearchContacts.length}
                                    itemSize={1}
                                    itemKey={searchContactKey}
                                    itemData={searchContactData}
                                    outerElementType={ChatScrollbarVirtualList}
                                    width={width}
                                  >
                                    {RenderSearchContactRow}
                                  </List>
                                )}
                              </AutoSizer>
                            )
                          }
                          {
                            currentTab === TAB_VALUE_PARTNERS && (
                              <AutoSizer>
                                {({ height, width }) => (
                                  <List
                                    height={height}
                                    itemCount={filteredSearchPartners.length}
                                    itemSize={1}
                                    itemKey={searchUserKey}
                                    itemData={createSearchUserData(handleAddRecipient, filteredSearchPartners)}
                                    outerElementType={ChatScrollbarVirtualList}
                                    width={width}
                                  >
                                    {RenderSearchUserRow}
                                  </List>
                                )}
                              </AutoSizer>
                            )
                          }
                          {
                            currentTab === TAB_VALUE_ORG_CLIENTS && (
                              <AutoSizer>
                                {({ height, width }) => (
                                  <List
                                    height={height}
                                    itemCount={filteredSearchOrgClients.length}
                                    itemSize={1}
                                    itemKey={searchUserKey}
                                    itemData={createSearchUserData(handleAddRecipient, filteredSearchOrgClients)}
                                    outerElementType={ChatScrollbarVirtualList}
                                    width={width}
                                  >
                                    {RenderSearchMemberRow}
                                  </List>
                                )}
                              </AutoSizer>
                            )
                          }
                          {
                            currentTab === TAB_VALUE_ORG_SUPPLIERS && (
                              <AutoSizer>
                                {({ height, width }) => (
                                  <List
                                    height={height}
                                    itemCount={filteredSearchOrgSuppliers.length}
                                    itemSize={1}
                                    itemKey={searchUserKey}
                                    itemData={createSearchUserData(handleAddRecipient, filteredSearchOrgSuppliers)}
                                    outerElementType={ChatScrollbarVirtualList}
                                    width={width}
                                  >
                                    {RenderSearchMemberRow}
                                  </List>
                                )}
                              </AutoSizer>
                            )
                          }
                          {
                            currentTab === TAB_VALUE_ORG_MEMBERS && (
                              <AutoSizer>
                                {({ height, width }) => (
                                  <List
                                    height={height}
                                    itemCount={filteredSearchOrgMembers.length}
                                    itemSize={1}
                                    itemKey={searchUserKey}
                                    itemData={createSearchUserData(handleAddRecipient, filteredSearchOrgMembers)}
                                    outerElementType={ChatScrollbarVirtualList}
                                    width={width}
                                  >
                                    {RenderSearchMemberRow}
                                  </List>
                                )}
                              </AutoSizer>
                            )
                          }
                        </div>
                      )
                    }
                  </Box>
                  {
                    newInviteShowWithEmail && (
                      <ListItem
                        button
                        sx={{
                          py: 0,
                          px: 2,
                          height: INVITE_ITEM_HEIGHT
                        }}
                        onClick={() => setEditInvitation(true)}
                        ref={anchorRef}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              width: 45,
                              height: 45,
                            }}
                          >
                            <UserAddIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <ListItemText
                            primary="Invite a new friend"
                            primaryTypographyProps={{
                              color: 'textPrimary',
                              noWrap: true,
                              variant: 'body1'
                            }}
                          />
                          <ListItemText
                            secondary="Not seeing your friend here? Send her/him a kind invite to join Accziom!"
                            secondaryTypographyProps={{
                              color: 'textSecondary',
                              noWrap: false,
                              variant: 'body2',
                              marginLeft: 1,
                              marginTop: -1
                            }}
                          />
                        </Box>
                      </ListItem>
                    )
                  }
                  {
                    (newContactShow || newMemberShow || newPartnerShow) && (
                      <ListItem
                        button
                        sx={{
                          py: 0,
                          px: 2,
                          height: INVITE_ITEM_HEIGHT
                        }}
                        onClick={() => setIsMsgboxIsOpen(true)}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              width: 45,
                              height: 45,
                            }}
                          >
                            <UserAddIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <ListItemText
                            primary={`Add a new ${currentTab}`}
                            primaryTypographyProps={{
                              color: 'textPrimary',
                              noWrap: true,
                              variant: 'body1'
                            }}
                          />
                          <ListItemText
                            secondary={`Not seeing your friend here? Add her/his card as a new ${currentTab} into your organization!`}
                            secondaryTypographyProps={{
                              color: 'textSecondary',
                              noWrap: false,
                              variant: 'body2',
                              marginLeft: 1,
                              marginTop: -1
                            }}
                          />
                        </Box>
                      </ListItem>
                    )
                  }
                  {
                    editInvitation && (
                      <Popover
                        anchorEl={anchorRef.current}
                        anchorOrigin={{
                          horizontal: 'right',
                          vertical: 'top'
                        }}
                        open
                        PaperProps={{
                          sx: {
                            minWidth: 350,
                            minHeight: 230,
                            ml: -10
                          }
                        }}
                        transitionDuration={500}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            height: 'calc(100% - 45px)',
                            p: 1
                          }}
                        >
                          <TextField
                            placeholder="Leave a message"
                            defaultValue={msgBody}
                            fullWidth
                            multiline
                            rows={9}
                            onChange={handleMessageChange}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            mb: 1,
                            mr: 1
                          }}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            sx={{
                              marginLeft: 1
                            }}
                            onClick={handleSendClick}
                          >
                            Send
                          </Button>
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() => setEditInvitation(false)}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Popover>
                    )
                  }
                </Box>
              </Box>
            </Box>
            <Divider sx={{ mt: 2 }} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2
              }}
            >
              <Button
                color="primary"
                size="medium"
                sx={{ ml: 2 }}
                type="button"
                onClick={() => {
                  if ((isTitled === ONLY_TITLE || isTitled === BOTH_TITLE_FIRSTMSG) && !invitingTitle) toast.error('You should input a new title.');
                  else if ((isTitled === ONLY_FIRSTMSG || isTitled === BOTH_TITLE_FIRSTMSG) && !firstMsg) toast.error('You should input a first message.');
                  else handleSubmit();
                }}
                disabled={isSubmitting}
                variant="contained"
              >
                {isSubmitting && (
                  <CircularProgress
                    color="primary"
                    size={20}
                    sx={{ marginRight: '8px' }}
                  />
                )}
                {isTitled > NO_TITLE_FIRSTMSG ? 'SUBMIT' : 'DONE'}
              </Button>
            </Box>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
            {
              isMsgboxIsOpen && (
                <DblButtonMessage
                  messageContent={LEAVE_WINDOW_CONTENT}
                  messageTitle={LEAVE_WINDOW_TITLE}
                  onClose={msgboxCloseHandler}
                  onYesClick={onYesClick}
                />
              )
            }
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

InviteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isTitled: PropTypes.number.isRequired,
  invitingTitle: PropTypes.string,
  setInvitingTitle: PropTypes.func,
  firstMsg: PropTypes.string,
  setFirstMsg: PropTypes.func,
  showTabs: PropTypes.array.isRequired,
  showUser: PropTypes.number,
  showRange: PropTypes.number,
  maxInvitees: PropTypes.number,
  invitees: PropTypes.array.isRequired,
  hideUserIds: PropTypes.array,
  loadingCompleted: PropTypes.bool,
  addInvitee: PropTypes.func.isRequired,
  removeInvitee: PropTypes.func.isRequired,
  toSubmit: PropTypes.func.isRequired,
  toClose: PropTypes.func.isRequired
};

export default InviteDialog;
