import { io } from 'socket.io-client';
import { accsiomURIs } from 'src/config';
import store from 'src/store';
import { addApprisings, removeApprising } from 'src/slices/apprising';
import { setInvitationNeedReload, setReferralNeedReload } from 'src/slices/network';
import type { Apprising } from 'src/../../Common/Model/apprising';
import { setNeedReload as setFileshareNeedReload } from 'src/slices/fileshare';
import { setNeedReload as setTicketNeedReload } from 'src/slices/request';
import { setCurOrgNeedReload } from 'src/slices/organization';
import { getPossibleHrefs } from 'src/utils/filterUrlsByRole';
import { lambdaRemoveApprising } from 'src/aws/lambdaDispatch';
import { setSyncState } from 'src/slices/contact';

const RECEIVE_CODE = 'receive';
const APPRISING_TYPE_ADD = 'add';
const APPRISING_TYPE_REMOVE = 'remove';

export const wsSocket = io(
  // process.env.NODE_ENV === 'production' ? accsiomURIs.filethread_url : 'http://localhost:8080',
  accsiomURIs.apprising_ws_url,
  { autoConnect: false }
);

export const wsConnect = (info: { id: string; type: number; name: string }) => {
  wsSocket.auth = info;
  wsSocket.connect();
};

export const wsDisconnect = () => {
  wsSocket.disconnect();
};

const onReceiveAppr = (params) => {
  const { content } = params;
  const appr = content.apprising as Apprising;
  // added by chengyang
  if (appr.href === '/mapping') {
    if (appr.itemId === 'XERO') store.dispatch(setSyncState(false, true));
    if (appr.itemId === 'QBO') store.dispatch(setSyncState(true, false));
    lambdaRemoveApprising(appr.id);
  }
  // ////////////
  const hrefs = getPossibleHrefs();
  if (appr.href !== '/management/file/share' && !hrefs.some((href) => appr.href.startsWith(href))) return;
  if (content.type === APPRISING_TYPE_ADD) {
    store.dispatch(addApprisings([appr]));
  } else if (content.type === APPRISING_TYPE_REMOVE) {
    store.dispatch(removeApprising(appr.id));
  }
  if (appr.type !== 'assign') {
    if (appr.href === '/management/network/partner') {
      store.dispatch(setInvitationNeedReload(true));
    } else if (appr.href.startsWith('/management/file')) {
      store.dispatch(setFileshareNeedReload(true));
    } else if (appr.href.startsWith('/deal') || appr.href.startsWith('/procurement')) {
      store.dispatch(setTicketNeedReload(true));
    } else if (appr.href === '/management/network/referral') {
      store.dispatch(setReferralNeedReload(true));
    } else if (appr.href.startsWith('/management/structure')) {
      store.dispatch(setCurOrgNeedReload(true));
    }
  }
};

wsSocket.on(RECEIVE_CODE, onReceiveAppr);

export const wsTeamSocket = io(
  // process.env.NODE_ENV === 'production' ? accsiomURIs.filethread_url : 'http://localhost:8080',
  accsiomURIs.apprising_ws_url,
  { autoConnect: false }
);

export const wsTeamConnect = (info: { id: string; type: number; name: string }) => {
  wsTeamSocket.disconnect();
  wsTeamSocket.auth = info;
  wsTeamSocket.connect();
};

export const wsTeamDisconnect = () => {
  wsTeamSocket.disconnect();
};

wsTeamSocket.on(RECEIVE_CODE, onReceiveAppr);
