import toast from 'react-hot-toast';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@material-ui/core';

export const infoToast = (txt: string): void => {
  toast(
    <Box
      display="flex"
      alignItems="center"
      sx={{
        borderRadius: 1
      }}
    >
      <InfoIcon
        sx={{
          fontSize: '24px',
          mr: 2
        }}
        color="secondary"
      />
      {txt}
    </Box>
  );
};
