import type { FC } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import { DblButtonMessageProps } from 'src/globals';

const DblButtonMessage: FC<DblButtonMessageProps> = (props): JSX.Element => {
  const { onYesClick, onNoClick, messageTitle, messageContent, onClose, noButtonTitle = 'NO', yesButtonTitle = 'YES' } = props;

  const handleYesClick = async () => {
    if (onYesClick) await onYesClick();
    onClose(false);
  };
  const handleNoClick = async () => {
    if (onNoClick) await onNoClick();
    onClose(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
    >
      <Box
        sx={{
          display: 'flex',
          pb: 2,
          pt: 3,
          px: 3
        }}
      >
        <Avatar
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.info.main, 0.08),
            color: 'info.main',
            mr: 2
          }}
        >
          <WarningIcon />
        </Avatar>
        <Box>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            {messageTitle}
          </Typography>
          <Typography
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body1"
          >
            {messageContent}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          px: 3,
          py: 1.5
        }}
      >
        <Button
          color="primary"
          sx={{ mr: 2 }}
          variant="outlined"
          onClick={async () => {
            await handleNoClick();
          }}
        >
          {noButtonTitle}
        </Button>
        <Button
          sx={{
            backgroundColor: 'error.main',
            '&:hover': {
              backgroundColor: 'error.dark'
            }
          }}
          variant="contained"
          onClick={async () => {
            await handleYesClick();
          }}
        >
          {yesButtonTitle}
        </Button>
      </Box>
    </Dialog>
  );
};

DblButtonMessage.propTypes = {
  onYesClick: PropTypes.any,
  onNoClick: PropTypes.func,
  messageTitle: PropTypes.string,
  messageContent: PropTypes.string,
  onClose: PropTypes.func
};

DblButtonMessage.defaultProps = {
  messageTitle: null,
  messageContent: null
};

export default DblButtonMessage;
